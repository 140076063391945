import React, { useState, useCallback, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// // import JoditEditor from "jodit-react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { toast } from "react-toastify";
 
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../assets/gif/loading.gif";
import "./Contenteditseries.css";
import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

function Contenteditseries() {
  const { id } = useParams();
  var seriesid = id;
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    genre_id: "",
    user_id: "",
    type: "",
    access: "",
    active: "",
    ppv_status: "",
    ios_ppv_price: "",
    details: "",
    description: "",
    featured: "",
    duration: "",
    views: "",
    rating: "",
    image: "",
    player_image: "",
    tv_image: "",
    embed_code: "",
    mp4_url: "",
    webm_url: "",
    ogg_url: "",
    language: "",
    year: "",
    trailer: "",
    banner: "",
    search_tag: "",
    series_trailer: "",
    season_trailer: "",
    uploaded_by: "",
    url: "",
    Thumbnail: "",
    deleted_at: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    image_url: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [manageseason, setManageseason] = useState([]);
  const [editseason, setEditseason] = useState([]);

  const [user_access, setUser_access] = useState([]);
  const [ios_ppv_pricename, setIos_ppv_pricename] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [useraccess, setUserAccess] = useState([]);
  const [useraccessseason, setUserAccessseason] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  // console.log(age_restrictdata)
  const [age_restrict, setAge_restrict] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [loading, setLoading] = useState(true);

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file drop here
    const file = acceptedFiles[0];
    setSelectedFile(file);
  }, []);

  const handleFileChangessss = (event) => {
    // Handle file selection from file input
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser({ ...editUser, year: value });
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  // console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/edit/${id}`,
        { headers: headers }
      );

      const data = response?.data?.Series[0]?.block_country_id;
      const dataavailable_country =
        response?.data?.Series[0]?.available_countries;
      const dataartists = response?.data?.Series[0]?.Seriesartist;
      const datacategories = response?.data?.Series[0]?.SeriesCategory;
      const datalanguages = response?.data?.Series[0]?.SeriesLanguage;
      const dataAge_Restrict = response?.data?.Series[0]?.selected_Age_Restrict;
      // const datarelated_video = response?.data?.selected_related_video;

      // var res = response?.data?.videos[0];
      // setEditUser(res);

      // var res = response?.data?.videos[0];
      // var globelset = response?.data?.ppv_gobal_price;

      // setInputValueAPI(globelset);

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.artist_id,
        label: item.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.category_id,
        label: item.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.language_id,
        label: item.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item.id,
      //   label: item.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      // setSelectedValuesrelated_video(formattedOptionsrelated_video);
      // setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      setLoading(false);
      setTags(
        response?.data?.Series[0]?.search_tag
          ?.split(",")
          ?.map((tag) => tag?.trim())
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.series_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      setIos_ppv_pricename(InappPurchase);
      setUserAccess(result);
      // console.log(InappPurchase)
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setLivestream_source(response?.data?.Livestream_source);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item.id,
      //   label: item.id,
      // }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          // const adsPositionOptions = data?.ads_position?.map((position) => ({
          //   value: position?.position,
          //   label: position?.name,
          // }));
          // // console.log(adsPositionOptions);
          // setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const indexseason = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();

      var result = resData?.user_access;
      // console.log(result)
      // console.log(resData)
      setUserAccessseason(result);
    };
    indexseason();

    const getUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/edit/${seriesid}`,
          { headers: headers }
        );

        var res = response?.data?.Series[0];
        setEditUser(res);
        setEditname(res);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    getUser();

    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("Content_Partner_id", 1);

        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/list`,
          formData,
          { headers: headers }
        );

        var result = response?.data?.SeriesSeason?.data;
        // setEditseason(result);
        // setManageseason(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
    const fetchDataseaon = async () => {
      try {
        const formData = new FormData();
        formData.append("Content_Partner_id", 1);

        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/unique-series-season/${seriesid}`,
          { headers: headers }
        );

        var result = response?.data?.SeriesSeason;
        setEditseason(result);
        setManageseason(result);
        console.log(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchDataseaon();
  }, [seriesid]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    // if (e.target.checked == true) {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 1 });
    //   setEditUser({
    //     ...editUser,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 0 });
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length == 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (!selectedValueslanguages || selectedValueslanguages?.length == 0) {
      setValidationMessagelanguages("Please select at least one Language.");
      setIsValidationHiddenlanguages(false);
      focusInputRef = languageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    const updatedTagsString = tags?.join(", ");

    const editInputvalue = {
      title: editUser?.title,
      Content_Partner_id: 4,
      type: editUser?.type,
      slug: editUser?.slug,
      id: editUser?.id,
      genre_id: editUser?.genre_id,
      user_id: editUser?.user_id,
      access: editUser?.access,
      details: editUser?.details,
      description: editUser?.description,
      active: editUser?.active,
      ppv_status: editUser?.ppv_status,
      featured: editUser?.featured,
      duration: editUser?.duration,
      views: editUser?.views,
      rating: editUser?.rating,
      image: editUser?.image,
      player_image: editUser?.player_image,
      tv_image: editUser?.tv_image,
      embed_code: editUser?.embed_code,
      mp4_url: editUser?.mp4_url,
      webm_url: editUser?.webm_url,
      ogg_url: editUser?.ogg_url,
      language: editUser?.language,
      year: editUser?.year,
      trailer: editUser?.trailer,
      banner: editUser?.banner,
      ppv_price: inputValue,
      search_tag: updatedTagsString,
      series_trailer: editUser?.series_trailer,
      season_trailer: editUser?.season_trailer,
      uploaded_by: editUser?.uploaded_by,
      url: editUser?.url,
      deleted_at: editUser?.deleted_at,
      image_url: editUser?.image_url,
      ads_position: editUser?.ads_position,
      languages_id: selectedValueslanguages?.map((option) => option?.value),
      artists: selectedValuesartists?.map((option) => option?.value),
      block_country_id: selectedValues?.map((option) => option?.value),
      genre_id: selectedValuescategories?.map((option) => option?.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option?.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("series_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/Image_upload`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("series_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }

        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status == false) {
        var resultError = resjson;
        // props.setApiresponsealert(resultError);
        // props.setShowalert(true);
        toast.error(resjson?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const [ppv_interval, setPpv_interval] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");

  async function series_season() {
    const formData = new FormData();

    formData.append("Content_Partner_id", 1);
    formData.append("series_id", seriesid);
    formData.append("ppv_price", inputValue);
    formData.append("access", storeliveuseraccessseason);
    formData.append("ppv_interval", ppv_interval);
    formData.append("ios_product_id", ios_product_id);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;

        resultapi = response.data;

        var result = response.data.SeriesSeason_id;

        const imageFormData = new FormData();
        imageFormData.append("season_id", result);
        if (selectedFile4) {
          imageFormData.append("image", selectedFile4);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/Image_upload`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();

        formDataseo.append("season_id", result);
        formDataseo.append("trailer", selectedFile);
        formDataseo.append("trailer_type", trailer_type);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/Trailer_upload`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        // props.setApiresponsealert(resultError);
        // props.setShowalert(true);
        toast.error(resultapi?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const deleteOperation = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/delete/` +
        id,
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status == true) {
          alert("Item" + response.data.message + "!");
          window.location.reload();
        } else {
          alert("Item" + response.data.message + "!");
        }
      });
  };

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function allseriesuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  id="ppv_price"
                  name="ppv_price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser?.ios_ppv_price}
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item.product_id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  const [allliveuseraccessseason, setallliveuseraccessseason] = useState("");
  const [storeliveuseraccessseason, setStoreliveuseraccessseason] =
    useState("");

  function allseriesuseraccessseason() {
    switch (allliveuseraccessseason) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="text"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios  mt-2">
                <select
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                  onChange={(e) => setIos_product_id(e.target.value)}
                >
                  <option value="">Choose an IOS PPV Price</option>
                  {ios_ppv_pricename?.map((item) => (
                    <option value={item?.product_id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-12 mt-2">
              <label className=" theme-text-color">PPV Interval</label>
              <p className="p1 theme-text-color">
                Please Mention How Many Episodes are Free
              </p>

              <div className="panel-body ppv_price_ios  mt-2">
                <input
                  type="text"
                  id="ppv_interval"
                  name="ppv_interval"
                  onChange={(e) => setPpv_interval(e.target.value)}
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input mt-2  form-control-lg"
              id="publish_time"
              name="publish_time"
            // onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="allserieschannel">
          <section className="container-fluid  theme-bg-color-secondary  pb-1">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3 className=" theme-text-color">Edit New Series</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Series
                  </button>
                </div>
              </div>
            </div>

            <div>{showOverlay && <ResponseLoader />}</div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">{editname?.title}</h5>
                  <hr className=" theme-text-color"></hr>
                  <div className=" text-start">
                    <label className="m-0 theme-text-color">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1 theme-text-color">
                      Add the series title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        value={editUser?.title}
                        onChange={handleInput}
                        placeholder="Series Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">Slug</label>
                    <p className="t-1 theme-text-color">Add a URL Slug</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        value={editUser?.slug}
                        onChange={handleInput}
                        placeholder="Series Slug"
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">
                      Short Description
                    </label>
                    <p className="t-1 theme-text-color">
                      Add a short description of the series below
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        id="description"
                        name="description"
                        onChange={handleInput}
                        value={editUser?.description}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">
                        Series Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        {/* <JoditEditor
                          className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Duration</label>
                      <p className="t-1 theme-text-color">
                        Enter The Series Duration In (HH : MM : SS)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          value={editUser?.duration}
                          maxLength="8"
                          // placeholder="duration"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Year</label>
                      <p className="t-1 theme-text-color">
                        Enter The Series Year
                      </p>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="number"
                          value={editUser?.year}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Organize</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Series Ratings
                      </label>
                      <p className="t-1 theme-text-color">
                        IMDb Ratings 10 out of 10
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.rating}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className=" mt-2 text-start">
                      <label className="m-0">Age Restrict :</label>
                      <p className="t-1">Select A Series Age Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.age_restrict}
                        >
                          {age_restrictdata.map((item) => (
                            <option selected value={item.id}>
                              {item.slug}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Cast and Crew
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Series Artist Below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Video Category Below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={optionscategories}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Language<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Video Language Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguages && (
                          <p>{validationMessagelanguages}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Block Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Series country Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={options}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Available Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Available the Video for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Search Tags</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className="col-sm-12">
                    <p className="p2 theme-text-color">
                      You don't have any search keywords.
                    </p>

                    <div className="mt-2">
                      <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        onChangeInput={(tag) => setNewTag(tag)}
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Series
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Series Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Series image( 16:9 Ratio or 1280X720px )
                    </p>

                    {/* <img src={editUser?.image_url} width="150" height="100" /> */}

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.image_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Player Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Series image( 16:9 Ratio or 1280X720px )
                    </p>
                    {/* <img
                      src={editUser?.player_image}
                      width="150"
                      height="100"
                    /> */}

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.player_image}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      TV Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Series image( 16:9 Ratio or 1280X720px )
                    </p>
                    {/* <img src={editUser?.tv_image} width="150" height="100" /> */}

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.tv_image}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Access</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        User Access
                      </label>
                      <p className="p1 theme-text-color">
                        Who Is Allowed To View This Series ?
                      </p>
                      <select
                        onChange={handleInput}
                        value={editUser?.access}
                        name="access"
                        id="access"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        {/* <option value={item.role}>{item.name}</option> */}
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{allseriesuseraccess()}</div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Status Settings</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this series Featured
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            // value={editUser?.featured}
                            className="rs-input"
                            defaultChecked={
                              editUser?.featured == 1 ? true : false
                            }
                            checked={editUser?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            value={editUser?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this series Active
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            // value={editUser?.active}
                            defaultChecked={
                              editUser?.active == 1 ? true : false
                            }
                            checked={editUser?.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            value={editUser?.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Enable this series as Slider
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInputenable}
                            className="r-input"
                            id="banner"
                            // value={editUser?.banner}
                            defaultChecked={
                              editUser?.banner == 1 ? true : false
                            }
                            checked={editUser?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInputenable}
                            id="banner"
                            value={editUser?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">Season Trailer</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInputenable}
                            className="r-input"
                            // value={editUser?.series_trailer}
                            defaultChecked={
                              editUser?.series_trailer == 1 ? true : false
                            }
                            checked={
                              editUser?.series_trailer == 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInputenable}
                            value={editUser?.series_trailer == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">SEO</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        Website Page Title
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_page_title}
                          name="website_page_title"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                          placeholder="website title"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0 theme-text-color">
                        Website URL
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_URL}
                          name="website_URL"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                          placeholder="website url"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="m-0 theme-text-color">
                        Meta Description
                      </label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          value={editUser?.Meta_description}
                          name="Meta_description"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                          placeholder="meta description"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  {/* <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="iq-card  theme-bg-color global-shadow">
              <div className="admin-section-title ">
                <div className="row p-3">
                  <div className="col-md-8">
                    <h3 className="fs-title  theme-text-color">
                      Manage Season &amp; Episodes
                    </h3>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end">
                    <Link>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Create Season
                      </button>
                    </Link>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content theme-bg-color global-shadow">
                      <div className="modal-header">
                        <h4 className="modal-title theme-text-color">
                          Add Season
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="modal-body">
                        <div>
                          <div className=" text-start mt-2">
                            <label className="m-0 theme-text-color">
                              {" "}
                              Season Thumbnail{" "}
                            </label>
                            <p className="t-1 theme-text-color">
                              (16:9 Ratio or 1280X720px)
                            </p>

                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className="col-6 col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() =>
                                      fileInputRef4.current.click()
                                    }
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef4}
                                      onChange={(event) =>
                                        handleFileChange(
                                          event,
                                          fileInputRef4,
                                          setSelectedFile4
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label className=" theme-text-color">
                                      <CameraRetroIcon className=" theme-text-color" />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6 col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile4 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(
                                                selectedFile4
                                              )}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() =>
                                                handleDelete(setSelectedFile4)
                                              }
                                              type="button"
                                              className="btn-close theme-text-color"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-2">
                            <label className=" theme-text-color">
                              Season Trailer
                            </label>
                            <div className="dropzoneStyle">
                              <Dropzone accept="video/*" onDrop={onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className="p-4 theme-text-color">
                                      Drag and drop a video file here, or click
                                      to select a file
                                    </p>
                                  </div>
                                )}
                              </Dropzone>
                              {selectedFile && (
                                <div>
                                  {/* <label> Season Trailer :</label>
                                   <h3>Selected File:</h3> */}
                                  <p className=" theme-text-color">
                                    {selectedFile?.name}
                                  </p>
                                </div>
                              )}
                              <input
                                type="file"
                                accept="video/*"
                                style={{ display: "none" }}
                                onChange={handleFileChangessss}
                              />
                            </div>
                          </div>

                          <div className="mt-2">
                            <div className="col-sm-12">
                              <label className="m-0 theme-text-color">
                                Choose User Access
                              </label>
                              <p className="p1 theme-text-color">
                                Who Is Allowed To View This Live Stream ?
                              </p>
                              <select
                                onChange={(e) =>
                                  setStoreliveuseraccessseason(e.target.value)
                                }
                                name="access"
                                id="access"
                                className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                                onClick={(event) => {
                                  setallliveuseraccessseason(
                                    event.target.value
                                  );
                                }}
                              >
                                {/* <option value={item.role}>{item.name}</option> */}
                                {useraccessseason?.map((item) => (
                                  <option value={item?.value}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>{allseriesuseraccessseason()}</div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={series_season}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row season_episode_header">
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th className=" theme-text-color">Seasons</th>
                        <th className=" theme-text-color">Operation</th>
                      </tr>
                      {editseason?.map((edit) => (
                        <tr>
                          <td valign="bottom">
                            <p className=" theme-text-color">
                              Season {edit?.id}{" "}
                            </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={"/cpp/edit-season/" + edit?.id}
                                className="edit ms-1 "
                              >
                                <img
                                  src={edititem}
                                  alt="flogo"
                                  width={15}
                                  height={15}
                                />
                                <span className="ms-2  theme-text-color">
                                  Edit Season
                                </span>
                              </Link>
                              <Link to="" className="delete ms-1">
                                <span onClick={() => deleteOperation(edit?.id)}>
                                  <img
                                    src={deleteitem}
                                    alt="flogo"
                                    width={15}
                                    height={15}
                                  />
                                  <span className="ms-2 theme-text-color">
                                    Delete
                                  </span>{" "}
                                </span>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th className=" theme-text-color">Episodes</th>
                        <th className=" theme-text-color">Operation</th>
                      </tr>
                      {manageseason?.map((manage) => (
                        <tr>
                          <td valign="bottom">
                            <p className=" theme-text-color">
                              {" "}
                              Episodes {manage?.id}{" "}
                            </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={
                                  "/cpp/manage-episode/" +
                                  seriesid +
                                  "/" +
                                  manage?.id
                                }
                                className="edit ms-1"
                              >
                                <img
                                  src={edititem}
                                  alt="flogo"
                                  width={15}
                                  height={15}
                                />{" "}
                                <span className="ms-2 theme-text-color">
                                  {" "}
                                  Manage Episodes
                                </span>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Contenteditseries;
