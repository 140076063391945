import "../../Styles/AccountPage.css";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMyContext } from "../Layout/CommonProvider";
import MessageBox from "../MessageBox";
import { IconMyCamera, IconMyUpload } from "../../assets/icons/MyIcons";
import { getItemToken } from "../../Utils/localStorageUtils";
import { profileDetailsAction } from "../../Redux/Actions/apiActions";
import ButtonWithLoader from "../../Pages/Music/CommonComponents/ButtonWithLoader";

const AccountPage = (props) => {

  const { profileDetails } = props

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { navigateTranslateChecker } = useMyContext()
  const [accountdata, setAccountData] = useState([]);
  const [profileImage, setProfileImage] = useState()
  const [loading, setLoading] = useState(true);
  const [formDataError, setFormDataError] = useState({})
  const [datatranscation, setDatatranscation] = useState({});

  const nameRef = useRef()
  const lastRef = useRef()

  const handleCoverImageChange = async (event, errorKey) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(selectedFile);
      };
      // await handleUpdate()
      reader.readAsDataURL(selectedFile);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true // AM/PM format
    };

    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return (
      <>
        <div>{formattedDate}</div>
      </>
    );
  }



  const calculateSubscriptionDuration = (days) => {
    if (days >= 365) {
      const years = Math.floor(days / 365);
      return years > 1 ? `${years} Years` : `${years} Year`;
    } else if (days >= 30) {
      const months = Math.floor(days / 30);
      return months > 1 ? `${months} Months` : `${months} Month`;
    } else {
      return `${days} Days : ${days} Day`;
    }
  };

  const subscriptionDuration = calculateSubscriptionDuration(datatranscation?.subscriptions_days);

  const formValidation = () => {
    let formIsValid = true;
    if (!accountdata?.name) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        name: "First name can't be empty",
      }));
      nameRef.current.focus();
      formIsValid = false;
    }
    if (!accountdata?.last_name) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        last_name: "Last name can't be empty",
      }));
      lastRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const handleUpdate = async (e) => {
    if (formValidation()) {
      setLoading(true);
      let formData = new FormData()
      formData.append("Login_device_type", "web")
      formData.append("name", accountdata?.name)
      formData.append("email", accountdata?.email)
      formData.append("last_name", accountdata?.last_name)
      formData.append("avatar", profileImage ? profileImage : accountdata?.avatar)
      formData.append("DOB", accountdata?.DOB)

      try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Profile-details-update`, formData, { headers: getItemToken('access_token') })
        const result = response?.data
        if (result?.status) {
          toast.success(t("Account Updated Successfully"));
          dispatch(profileDetailsAction());
          setProfileImage(null)
          setLoading(false);
        }
        else { toast.error(t("Request failed with status: " + response?.status)) }
      } catch (error) {
        const errorMessage = error?.response?.data?.message
        toast.error(t(errorMessage))
        setLoading(false);
      }
    }
  };

  const userDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_Baseurl}/Front-End/user-details`, {
        method: "POST",
        headers: getItemToken('access_token'),
      });
      const result = await response.json();
      setDatatranscation(result.transaction_details?.current_Subscription);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction data", error);
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setAccountData({
      ...accountdata,
      [name]: value,
    });
    setFormDataError((prev) => ({
      ...prev,
      [name]: ""
    }))
  }

  useEffect(async () => {
    setAccountData(profileDetails?.Profile_details);
  }, [profileDetails]);

  useEffect(() => {
    userDetails();
  }, []);

 return (
    <>
      <section className="container-fluid account px-2  px-md-3 px-lg-5">
        <h2 className="text-center theme-text-color py-4">My Account</h2>
        <div className="row m-0">
          <div className="col-12 col-md-6  px-2 px-md-4 ">
            <h5 className="accountTitle theme-text-color pb-4 d-md-none d-block ">{t("Display Picture")}</h5>
            <div className='position-relative profileImageUpdate  d-md-none d-block  mb-5 ' >
              <img key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.Profile_details?.user_avatar} alt={'logo'} className="profileImageUpdate rounded-circle overflow-hidden  object-fit-cover" />
              <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
              </span>
              <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
              <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} />
            </div>
            <h5 className="accountTitle theme-text-color pb-4 ">{t("Personal Details")}</h5>

            <MessageBox text={'First Name'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="text" name="name" ref={nameRef} className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.name} onChange={handleInputChange} />
            {formDataError?.name && (<MessageBox text={`${formDataError?.name}`} classname='errorred mb-2 d-block' />)}

            <MessageBox text={'Last Name'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="text" name="last_name" ref={lastRef} className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.last_name} onChange={handleInputChange} />
            {formDataError?.last_name && (<MessageBox text={`${formDataError?.last_name}`} classname='errorred mb-2 d-block' />)}

            <MessageBox text={'Mobile Number'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="number" name="mobile" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.mobile} onChange={handleInputChange} disabled={true} />

            <MessageBox text={'Email'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="email" name="email" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.email} onChange={null} disabled={true} />

            <MessageBox text={'Date of Birth'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="date" name="DOB" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.DOB} onChange={handleInputChange} />
            <div className="d-flex align-items-center justify-content-between mt-3 mb-5 ">
              <Link to={navigateTranslateChecker(`/verify/forget`)} className="text-decoration-underline">  <MessageBox text={'Change Password'} classname='account-input theme-text-color d-block' /></Link>
              <ButtonWithLoader action={handleUpdate} text="Update" loading={loading} className='bgButton px-3 py-2 rounded-2 d-block width-max ms-auto ' />
            </div>
          </div>

          <div className="col-12 col-md-6  px-2 px-md-4">
            <h5 className="accountTitle theme-text-color pb-4 d-md-block d-none ">{t("Display Picture")}</h5>
            <div className='position-relative profileImageUpdate  d-md-block d-none  mb-5 ' >
              <img key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.Profile_details?.user_avatar} alt={'logo'} className="profileImageUpdate rounded-circle overflow-hidden  object-fit-cover" />
              <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
              </span>
              <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
              <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} />
            </div>
            {/* <h5 className="accountTitle theme-text-color pb-4 ">{t("Tv Activation Code")}</h5>
            <MessageBox text={'TV Code'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="text" name="activation_code" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.activation_code} onChange={null} disabled /> */}

            {accountdata?.role == 1 || accountdata?.role == "1" ? (
              <>
                <h5 className="accountTitle theme-text-color pb-4 ">{t("Membership Settings")}</h5>
                <MessageBox text={'Current Membership'} classname='account-label mb-2 theme-text-color' />
                <MessageBox text={accountdata?.package} classname='accountTitle px-2 mb-2 theme-text-color' />
              </>
            ) : null}

            {accountdata?.role !== 1 || accountdata?.role !== "1" && accountdata?.subscription_status === 0 || accountdata?.subscription_status === "0" ? (
              <>
                <h5 className="accountTitle theme-text-color pb-4 ">{t("Subscriptions")}</h5>
                <MessageBox text={'Why Wait When You Can Enjoy All Rounder'} classname='account-input mb-2 theme-text-color' />
                <MessageBox text={'Entertainment!'} classname='accountTitle px-2 mb-2 theme-text-color' />
                <Link to={navigateTranslateChecker(`/plan`)} className="bgButton width-max d-block my-3 px-3 py-2 rounded-2">
                  <MessageBox text="Become a Subscriber"></MessageBox>
                </Link>
              </>
            ) : null}




            {accountdata?.role == 3 ? (
              <>
                <h5 className="accountTitle theme-text-color pb-4 ">{t("YOUR MEMBERSHIP")}</h5>
                {datatranscation !== null &&
                  <>
                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Price" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{`${datatranscation?.currency?.symbol} ${datatranscation?.subscription_price} / ${subscriptionDuration}`} </p>
                      <MessageBox text="(after your free trail ends)" classname='account-input theme-text-color'></MessageBox>
                    </div>

                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Next Billing Date :" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{formatDate(datatranscation?.subscriptions_ends_at)}</p>
                    </div>

                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Starting on" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{formatDate(datatranscation?.subscriptions_ends_at)}</p>
                      <MessageBox text="Your card will automatically" classname='account-input theme-text-color '></MessageBox>
                    </div>


                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Be Charged" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{`${datatranscation?.currency?.symbol} ${datatranscation?.subscription_price} / ${subscriptionDuration}`} </p>
                    </div>

                    {/* <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Be Charged" classname='account-input  theme-text-color'></MessageBox>
                      <p className="account-label theme-text-color">{datatranscation?.currency?.symbol} {datatranscation?.subscription_price / subscriptionDuration}</p>
                      <MessageBox text="After your free trail ends" classname=' account-label theme-text-color'></MessageBox>
                    </div> */}
                  </>
                }
              </>
            ) : null}

            {accountdata?.role == 3 || accountdata?.role == 1 ? <Link to={navigateTranslateChecker(`/transcation-list`)} className="bgButton width-max d-block mt-3 px-3 py-2 rounded-2">
              <MessageBox text="Transaction History"></MessageBox>
            </Link> : null}
          </div>
        </div>
      </section >
    </>
  );
};
const mapStateToProps = state => ({
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
});

export default connect(mapStateToProps)(AccountPage);