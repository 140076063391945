import React, { useState, useEffect, useRef } from "react";
// import JoditEditor from "jodit-react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import "./Edit_Episode_manage.css";
import { toast } from "react-toastify";
 
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../assets/gif/loading.gif";
import Dropzone from "react-dropzone";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminSaveHeader from "../../../components/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import AdminCustomButton from "../../../components/adminCommonComponents/AdminCustomButton";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageBox from "../../../components/MessageBox";
import MessageSubHeading from "../../CommonComponents/MessageSubHeading";
import { IoClose } from "react-icons/io5";
import AdminDeleteModal from "../../../components/adminCommonComponents/AdminDeleteModal";


function Edit_Episode_manage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    series_id: "",
    season_id: "",
    title: "",
    slug: "",
    free_duration_status: "",
    episode_description: "",
    type: "",
    access: "",
    ppv_status: "",
    ppv_price: "",
    mp4_url: "",
    url: "",
    path: "",
    disk: "",
    stream_path: "",
    processed_low: "",
    converted_for_streaming_at: "",
    episode_order: "",
    active: "",
    skip_recap: "",
    skip_intro: "",
    ios_ppv_price: "",
    ios_plan_price: "",
    recap_start_time: "",
    recap_end_time: "",
    intro_start_time: "",
    intro_end_time: "",
    featured: "",
    banner: "",
    footer: "",
    duration: "",
    age_restrict: "",
    views: "",
    rating: "",
    status: "",
    free_content_duration: "",
    search_tags: "",
    ads_position: "",
    episode_ads: "",
    uploaded_by: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const [publish_time, setPublish_time] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const handleInputChangetag = (input) => {
    setNewTag(input);
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (newTag.trim() !== "") {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    }
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser({ ...editUser, year: value });
    }
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const formattedInput = inputValue.replace(/\D/g, "");
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };
  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= editUser.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= editUser.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else if (formattedTime === editUser.skip_start_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session cannot be the same as Skip Start Time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= editUser.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= editUser.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else if (formattedTime === editUser.recap_start_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session cannot be the same as Recap Start Time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, episode_description: e });
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/edit/${id}`,
        { headers: headers }
      );

      const data = response?.data?.episode[0]?.block_country_id;
      const dataavailable_country =
        response?.data?.episode[0]?.available_countries;
      const dataartists = response?.data?.episode[0]?.selected_artist;
      const datacategories = response?.data?.episode[0]?.selected_category;
      const datalanguages = response?.data?.episode[0]?.selected_language;
      const dataAge_Restrict =
        response?.data?.episode[0]?.selected_Age_Restrict;
      var InappPurchase = response?.data?.InappPurchase;
      const subtitlesmap = response?.data?.episode_subtitle;
      setSubtitlesmap(subtitlesmap);
      setInappPurchase(InappPurchase);
      var globelset = response?.data?.ppv_gobal_price;
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      // setSelectedValuesrelated_video(formattedOptionsrelated_video);
      // setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);

      setTags(
        response?.data?.episode[0]?.search_tags
          ? response.data.episode[0].search_tags?.split(",").map((tag) => tag.trim())
          : []
      );
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.livestream_artist;
      const datacategories = response?.data?.livestream_category;
      const datalanguages = response?.data?.livestream_language;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setLivestream_source(response?.data?.Livestream_source);
      var result = response?.data?.user_access;
      setUser_access(result);
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsrelated_video(formattedOptionsrelated_video);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const deleteOperationsubtitle = () => {
    const access_token = localStorage.getItem("access_token");

    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/deleteOperationsubtitle/` + id,
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status == true) {
          alert("Item" + response.data.message + "!");
          window.location.reload();
        } else {
          alert("Item" + response.data.message + "!");
        }
      });
  };

  // MULTI SELECT NEW

  const convertToTitleCase = (str) => {
    return str
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formattedDatatype = convertToTitleCase(editUser?.type);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.episode[0];
      // var result = resData.user_access
      setEditUser(res);
      setEditname(res);
      setLoading(false);
      // setUseraccess(result)
    };
    getUser();

    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          // setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/List`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.SeriesGenre?.data;
        // setData(result);
      })
      .catch((error) => console.log(error));

  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const updatedTagsString = tags?.join(", ");

    const editInputvalue = {
      episode_id: editUser?.id,
      user_id: editUser?.user_id,
      series_id: editUser?.series_id,
      season_id: editUser?.season_id,
      title: editUser?.title,
      slug: editUser?.slug,
      episode_description: editUser?.episode_description,
      type: editUser?.type,
      access: editUser?.access,
      ios_plan_price: editUser?.ios_plan_price,
      ppv_status: editUser?.ppv_status,
      uploaded_by: editUser?.uploaded_by,
      ppv_price: inputValue,
      mp4_url: editUser?.mp4_url,
      url: editUser?.url,
      path: editUser?.path,
      disk: editUser?.disk,
      stream_path: editUser?.stream_path,
      processed_low: editUser?.processed_low,
      converted_for_streaming_at: editUser?.converted_for_streaming_at,
      episode_order: editUser?.episode_order,
      active: editUser?.active,
      skip_start_time: editUser?.skip_start_time,
      skip_end_time: editUser?.skip_end_time,
      skip_start_session: editUser?.skip_start_session,
      recap_start_time: editUser?.recap_start_time,
      recap_end_time: editUser?.recap_end_time,
      recap_start_session: editUser?.recap_start_session,
      featured: editUser?.featured,
      banner: editUser?.banner,
      footer: editUser?.footer,
      duration: editUser?.duration,
      age_restrict: editUser?.age_restrict,
      views: editUser?.views,
      rating: editUser?.rating,
      status: editUser?.status,
      free_duration_status: editUser?.free_duration_status,
      free_duration_time: editUser?.free_duration_time,
      search_tags: updatedTagsString,
      ads_position: editUser?.ads_position,
      episode_ads: editUser?.episode_ads,
      free_content_duration: editUser?.free_content_duration,
      // languages_id: selectedValueslanguages.map((option) => option.value),
      // artist_id: selectedValuesartists.map((option) => option.value),
      block_country_id: selectedValues?.map((option) => option?.value),
      // category_id: selectedValuescategories.map((option) => option.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option?.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let fourthApiStatus = false;

    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("episode_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/Image-upload`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("episode_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("episode_id", id);

        Object.keys(videosubtitles).forEach((language, index) => {
          formDatasubtitles.append(
            `short_code[${index}]`,
            Subtitlesmap[index].short_code
          );
          formDatasubtitles.append(
            `language[${index}]`,
            Subtitlesmap[index].language
          );
          videosubtitles[language].forEach((file) => {
            formDatasubtitles.append(`subtitles[${index}]`, file);
          });
        });

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/Subtitles`,
          formDatasubtitles,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }

        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }

        if (formDatasubtitlesresponse?.data?.status === true) {
          fourthApiStatus = true;
        }

        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      fourthApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };


  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input mt-2  form-control-lg"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function allepisodeuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input"
                  placeholder="PPV Price"
                  id="ppv_price"
                  name="ppv_price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  name="ios_plan_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser?.ios_plan_price}
                  className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.product_id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

        );
      default:
        return null;
    }
  }

  const episodedeleteOperation = () => {
    setShowOverlay(true)
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/subtitles-delete/${deleteId?.id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status == true) {
          var responseData = response?.data;
          toast.success(responseData?.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            toast.error(resultError?.message);
            showOverlay(true)
          }
        }
      });
  };

  const handleOpenModal = (id, name) => {
    setShowModal(!showModal)
    setDeleteId({
      id: id,
      name: name
    })
  }
  const closeModal = () => {
    setShowModal(!showModal)
  }
  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      {showModal && <AdminDeleteModal modelClose={showModal} deleteClose={closeModal} deleteAction={episodedeleteOperation} buttonText='custom' message={`Are you sure you want to delete ${deleteId?.name} subtitle file?`} />}
      <AdminSaveHeader heading="Edit Episode" saveText="Update Episode" saveMethod={handleUpdate} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text={editname?.title} />
            <MessageLabel text="Title" className='mandatorySimple' />
            <MessageSubHeading text="Add the episodes title in the textbox below" />
            <input
              type="text"
              id="title"
              name="title"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              onChange={handleInput}
              value={editUser?.title}
              placeholder="Episode Title"
              ref={titleInputRef}
            />
            {!isValidationHiddentitle && (<MessageBox text={`${validationMessagetitle}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" />
            <MessageSubHeading text="Add the episodes slug in the textbox below" />
            <input
              type="text"
              id="slug"
              name="slug"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              onChange={handleInput}
              value={editUser?.slug}
              placeholder="Episode Slug"
            />
            {!isValidationHiddenslug && (<MessageBox text={`${validationMessageslug}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Episode Description" />
            <MessageSubHeading text="Add a short description of the series below" />
            {/* <JoditEditor
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              value={editUser?.episode_description}
              onChange={(e) => {
                handleChangeFirstdetails(e);
              }}
            /> */}
            <MessageLabel text="Duration" />
            <MessageSubHeading text=" Enter the episode duration in the following format (Hours : Minutes : Seconds)" />
            <input
              type="text"
              name="duration"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              value={editUser?.duration}
              maxLength="8"
              placeholder="duration"
              onChange={handleInputdata}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Links" />
            <MessageLabel text="Embed Link" /><div className="d-flex align-items-center ">
              <Link to='https://facebook.com' target="_blank" className="share-ico">
                <FaFacebookF className="theme-text-color fs-5" />
              </Link>
              &nbsp;
              <Link to="https://twitter.com" target="_blank" className="share-ico">
                <FaXTwitter className=" theme-text-color fs-5" />
              </Link>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Organize' />
            <MessageLabel text="Episode Ratings" />
            <MessageSubHeading text="IMDb Ratings 10 out of 10" />
            <select className="form-select theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" id="rating" name="rating" size="lg" onChange={handleInput} value={editUser?.rating}  >
              {Array.from({ length: 11 }).map((data, index) => { return <option value={index} className="theme-bg-color theme-text-color">{index}</option> })}
            </select>
            <MessageLabel text="Age Restrict" />
            <MessageSubHeading text="Select A episode Age Below" />
            <select
              className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="age_restrict"
              name="age_restrict"
              size="lg"
              onChange={handleInput}
              value={editUser?.age_restrict}
            >
              {age_restrictdata?.map((item) => (
                <option value={item?.id} className="theme-bg-color theme-text-color">{item?.slug}</option>
              ))}
            </select>
            <MessageLabel text="Block Country" />
            <MessageSubHeading text="Select A episode country Below" />
            <Select
              options={options}
              isMulti
              className="theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              onChange={handleSelectChange}
              value={selectedValues}
            />
            <MessageLabel text="Available Country" />
            <MessageSubHeading text="Available the Video for Selected Country" />
            <Select
              options={optionsavailable_country}
              isMulti
              className=" border-0 theme-bg-color-secondary custom-placeholder theme-text-color"
              onChange={handleSelectChangeavailable_country}
              value={selectedValuesavailable_country}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Search Tags' />
            <MessageLabel text="You don't have any search keywords." />
            <TagsInput
              value={tags}
              onChange={handleTagsChange}
              inputValue={newTag}
              onChangeInput={handleInputChangetag}
              inputProps={{ onKeyPress: handleInputKeyPress }} // Adding key press event handler
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Free Duration' />
            <div className="mx-0 my-2 row ">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Free Duration Status" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input
                    name="free_duration_status"
                    id="free_duration_status"
                    onChange={handleInputenable}
                    defaultChecked={
                      editUser?.free_duration_status == 1 ? true : false
                    }
                    checked={
                      editUser?.free_duration_status == 1 ? true : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    id="free_duration_status"
                    name="free_duration_status"
                    onChange={handleInputenable}
                    value={
                      editUser?.free_duration_status == 1 ? "1" : "0"
                    }
                  ></span>
                </label>
              </div>
            </div>
            <MessageLabel text="Free Duration Time" />
            <input
              type="text"
              name="free_duration_time"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color border-0"
              value={editUser?.free_duration_time}
              maxLength="8"
              placeholder="HH:MM:SS"
              onChange={handleInputdata}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text='SEO' />
            <MessageLabel text="Website Page Title" />
            <input
              type="text"
              value={editUser?.website_page_title}
              name="website_page_title"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              onChange={handleInput}
              placeholder=""
            />
            <MessageLabel text="Website URL" />
            <input
              type="text"
              value={editUser?.website_URL}
              name="website_URL"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              onChange={handleInput}
              placeholder=""
            />
            <MessageLabel text="Meta Description" />
            <textarea
              type="text"
              value={editUser?.Meta_description}
              name="Meta_description"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
              onChange={handleInput}
              placeholder=""
            ></textarea>
          </Wrapper>
        </LeftWrapper >
        <RightWrapper>
          {editUser?.episode_url ? (
            <Wrapper>
              <MessageHeading text={formattedDatatype} />
              {/* <ReactPlayer
                url={editUser?.episode_url}
                controls={true}
                width="100%"
                height="230px"
              /> */}
            </Wrapper>
          ) : null}

          <Wrapper>
            <MessageHeading text='Thumbnails' />
            <MessageLabel text="Episode Image Cover" />
            <MessageSubHeading text="Select the episodes image (1080 X 1920px or 9:16 ratio)" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.Thumbnail}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef1.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef1,
                        setSelectedFile1
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy text-end theme-border-color">
                      <div className="row mx-0">
                        <div className=" col-10 p-0 ">
                          <img
                            src={URL?.createObjectURL(selectedFile1)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile1)
                            }
                            type="button"
                            className="bg-transparent p-0 theme-text-color"
                          ><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Episode Player Image" />
            <MessageSubHeading text="Select the player image ( 1280 X 720px or 16:9 Ratio )" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.Player_thumbnail}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef2.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef2,
                        setSelectedFile2
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 ">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy  theme-border-color text-end">
                      <div className="row mx-0 ">
                        <div className="col-10 p-0">
                          <img
                            src={URL?.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile2)
                            }
                            type="button"
                            className="bg-transparent theme-text-color"
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Episode TV Image" />
            <MessageSubHeading text="Select the player image ( 16:9 Ratio or 1920 X 1080 px)" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.TV_Thumbnail}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="mx-0 mt-3 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef3.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef3}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef3,
                        setSelectedFile3
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile3 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0">
                          <img
                            src={URL?.createObjectURL(selectedFile3)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile3)
                            }
                            type="button"
                            className="bg-transparent theme-text-color"
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper >
            <MessageHeading text="Intro Time (Optional)" />
            <div className="mx-0 row">
              <div className="col-6 ps-0">
                <MessageLabel text="Skip Start Time" />
                <MessageSubHeading text="(Please Give In Seconds)" />
                <input
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  name="skip_start_time"
                  id="skip_start_time"
                  maxLength="8"
                  placeholder="HH:MM:SS"
                  value={editUser?.skip_start_time}
                  onChange={handleInputdata}
                />
                {errors?.error_skip_start_time && (<MessageBox text={`${errors?.error_skip_start_time}`} classname='errorred mb-2 d-block' />)}
              </div>
              <div className="col-6 pe-0">
                <MessageLabel text="Recap Start Time" />
                <MessageSubHeading text="(Please Give In Seconds)" />
                <input
                  type="text"
                  maxLength="8"
                  value={editUser?.recap_start_time}
                  onChange={handleInputdata}
                  placeholder="HH:MM:SS"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  name="recap_start_time"
                  id="recap_start_time"
                />
                {errors?.error_recap_start_time && (<MessageBox text={`${errors?.error_recap_start_time}`} classname='errorred mb-2 d-block' />)}
              </div>
              <div className="col-6 ps-0">
                <MessageLabel text="Skip End Time" />
                <MessageSubHeading text="(Please Give In Seconds)" />
                <input
                  type="text"
                  maxLength="8"
                  value={editUser?.skip_end_time}
                  onChange={handleInputdata}
                  placeholder="HH:MM:SS"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  name="skip_end_time"
                  id="skip_end_time"
                />
                {errors?.error_skip_end_time && (<MessageBox text={`${errors?.error_skip_end_time}`} classname='errorred mb-2 d-block' />)}
              </div>
              <div className="col-6 pe-0">
                <MessageLabel text="Recap End Time" />
                <MessageSubHeading text="(Please Give In Seconds)" />
                <input
                  type="text"
                  maxLength="8"
                  value={editUser?.recap_end_time}
                  onChange={handleInputdata}
                  placeholder="HH:MM:SS"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  name="recap_end_time"
                  id="recap_end_time"
                />
                {errors?.error_recap_end_time && (<MessageBox text={`${errors?.error_recap_end_time}`} classname='errorred mb-2 d-block' />)}
              </div>
              <div className="col-6 ps-0">
                <MessageLabel text="Skip Start Session" />
                <MessageSubHeading text="(Please Give In Seconds)" />
                <input
                  type="text"
                  maxLength="8"
                  value={editUser?.skip_start_session}
                  onChange={handleInputdata}
                  placeholder="HH:MM:SS"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  name="skip_start_session"
                  id="skip_start_session"
                />
                {errors?.error_skip_start_session && (<MessageBox text={`${errors?.error_skip_start_session}`} classname='errorred mb-2 d-block' />)}
              </div>
              <div className="col-6 pe-0">
                <MessageLabel text="Recap Start Session" />
                <MessageSubHeading text="(Please Give In Seconds)" />
                <input
                  type="text"
                  maxLength="8"
                  value={editUser?.recap_start_session}
                  onChange={handleInputdata}
                  placeholder="HH:MM:SS"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  name="recap_start_session"
                  id="recap_start_session"
                />
                {errors?.error_recap_start_session && (<MessageBox text={`${errors?.error_recap_start_session}`} classname='errorred mb-2 d-block' />)}
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="mx-0 row mb-2">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this episode Featured" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="featured"
                    onChange={handleInputenable}
                    className="rs-input"
                    defaultChecked={
                      editUser?.featured == 1 ? true : false
                    }
                    checked={editUser?.featured == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="featured"
                    onChange={handleInputenable}
                    value={editUser?.featured == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="mx-0 row mb-2">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this episode Active" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="active"
                    onChange={handleInputenable}
                    defaultChecked={editUser?.active == 1 ? true : false}
                    checked={editUser?.active == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="active"
                    onChange={handleInputenable}
                    value={editUser?.active == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="mx-0 row ">
              <div className="col-9 ps-0 ">
                <MessageLabel text="Is this episode display in Banner" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="banner"
                    onChange={handleInputenable}
                    className="r-input"
                    defaultChecked={editUser?.banner == 1 ? true : false}
                    checked={editUser?.banner == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="banner"
                    onChange={handleInputenable}
                    value={editUser?.banner == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>

          <Wrapper>
            <MessageHeading text="Subtitles (WebVTT (.vtt))" />
            {Subtitlesmap?.map((item, index) => (
              <div className="mb-2" key={index}>
                <MessageLabel text={item?.language} />
                <div className="row mx-0 mb-3">
                  {item?.subtitle_url ? (
                    <>
                      <div className="col-5 ps-0">
                        <a href={item?.subtitle_url} target="_blank" download="name">
                          <div className="py-2 d-flex align-items-center gap-2">
                            <FaFileAlt fill="currentColor" style={{ width: "20px", height: "20px" }} />
                            <MessageBox classname='theme-text-color' text="Download" />
                          </div>
                        </a>
                      </div>
                      <div className="col-5 pe-0  align-self-center">
                        <span onClick={() => handleOpenModal(item?.subtitle_id, item?.language)} >
                          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <g>
                              <rect  className="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                              <line  className="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                              <g>
                                <line  className="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                <line  className="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                              </g>
                              <path  className="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="file-draganddrop  theme-border-color mt-2">
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleDropsubtitles(
                        item?.short_code,
                        acceptedFiles
                      )
                    }
                    accept="video/*"
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <MessageBox text="Drag and drop subtitles videos here" />
                        {videosubtitles[item?.short_code]?.length >
                          0 && (
                            <MessageLabel text={`${videosubtitles[item?.short_code].length} ${videosubtitles[item?.short_code].length ==
                              1
                              ? "file"
                              : "files"} selected`} >
                            </MessageLabel>
                          )}
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            ))}
          </Wrapper >
        </RightWrapper >
        <AdminCustomButton saveMethod={handleUpdate} saveText="Update Episode" className="ms-auto" />
      </FormContainer >
    </>
  );
}

export default Edit_Episode_manage;
