import { URLGenerater } from "../../Pages/CommonMethods/CommonMethods";
const sortByYear = (items) => {
    items.sort((a, b) => {
        const yearA = a.video_year || a.year;
        const yearB = b.video_year || b.year;

        if (!yearA) return 1;
        if (!yearB) return -1;

        const dateA = new Date(yearA);
        const dateB = new Date(yearB);

        if (isNaN(dateA)) return 1;
        if (isNaN(dateB)) return -1;

        return dateB - dateA;
    });
};

const sortYearsInHomePageData = (homePageData) => {
    return homePageData.map(source => {
        if (source.data) {
            sortByYear(source.data);
        }
        const categoryKeyMap = {
            "Videos_based_on_Categories": "category_videos",
            "Series_based_on_Genres": "category__series",
            "Live_Stream_based_on_Categories": "category_livestream",
            "Audio_based_on_Genre": "category__audios"
        };
        const categoryKey = categoryKeyMap[source.source];
        if (categoryKey && source.data) {
            source.data.forEach(video => {
                if (video[categoryKey]) {
                    sortByYear(video[categoryKey]);
                }
            });
        }
        return source;
    });
};


const footerReducer = (state = { logo: [], footerlink: [], sociallink: {}, companyname: null, error: null, isLoading: false, activeyear: null }, action) => {
    switch (action.type) {
        case "GET_FOOTER_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "GET_FOOTER_LINKS": return {
            ...state,
            logo: action.payload.Logos,
            footerlink: action.payload.Footer_Menu,
            sociallink: action.payload.Socail_media_link,
            companyname: action.payload.WebsiteName,
            activeyear: new Date().getFullYear(),
            isLoading: false,
        }
        case "GET_FOOTER_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};

const homeBannerReducer = (state = { sliderData: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_HOMEBANNER_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "GET_HOMEBANNER": return {
            ...state,
            sliderData: action.payload,
            isLoading: false,
        }
        case "GET_HOMEBANNER_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};

const viewallReducer = (state = { viewAllData: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_VIEWALL_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "GET_VIEWALL": return {
            ...state,
            viewAllData: action.payload,
            isLoading: false,
        }
        case "GET_VIEWALL_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}
const settingsReducer = (state = { settings: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SETTING": return {
            ...state,
            settings: action.payload.setting,
            settingsall: action.payload,
            isLoading: false,
        }
        case "GET_SETTING_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}
const filterReducer = (state = { filter: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_FILTER": return {
            ...state,
            filter: action.payload,
            isLoading: false,
        }
        case "GET_FILTER_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

const siteThemeReducer = (state = { siteTheme: null, error: null, isLoading: false }, action) => {
    switch (action.type) {
        case "GET_SITETHEME_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "GET_SITETHEME": return {
            ...state,
            siteTheme: action.payload,
            currentTheme: Number(action?.payload?.current_theme?.slice(-1)),
            isLoading: false,
        }
        case "GET_SITETHEME_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

const displayTranslateLanguageReducer = (state = { languages: [], error: null, isLoading: false }, action) => {
    switch (action.type) {
        case "GET_DISPLAYTRANSLATELANGUAGE_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "GET_DISPLAYTRANSLATELANGUAGE": return {
            ...state,
            languages: action.payload,
            isLoading: false,
        }
        case "GET_DISPLAYTRANSLATELANGUAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

const getUserTranslateLanguageReducer = (state = { languageDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_USERTRANSLATELANGUAGEACTION_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case "GET_USERTRANSLATELANGUAGEACTION":
            return {
                ...state,
                languageDetailsData: action.payload,
                isLoading: false
            };
        case "GET_USERTRANSLATELANGUAGEACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

const videosDetailsReducer = (state = { videoDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_VIDEODETAILSACTION_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null,
                videoDetailsData: []
            };
        case "GET_VIDEODETAILSACTION":
            return {
                ...state,
                videoDetailsData: action.payload,
                isLoading: false
            };
        case "GET_VIDEODETAILSACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const seriesDetailsReducer = (state = { seriesDetailsData: [], seriesDetailsData1: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SERIESDETAILSACTION":
            return {
                ...state,
                seriesDetailsData1: action.payload,
                seriesDetailsData: action.payload?.series_details,
                isLoading: false
            };
        case "GET_SERIESDETAILSACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const episodeDetailsReducer = (state = { episodeDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_EPISODEDETAILSACTION":
            return {
                ...state,
                episodeDetailsData: action.payload,
                isLoading: false
            };
        case "GET_EPISODEDETAILSACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const seasonDetailsReducer = (state = { seasonDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SEASONDETAILSACTION":
            return {
                ...state,
                seasonDetailsData: action.payload,
                isLoading: false
            };
        case "GET_SEASONDETAILSACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const liveDetailsReducer = (state = { rentpayment_setting: [], liveDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_LIVEDETAILSACTION":
            return {
                ...state,
                liveDetailsData: action.payload,
                rentpayment_setting: action.liveResponsePayment_setting,
                isLoading: false
            };
        case "GET_LIVEDETAILSACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const playDetailsReducer = (state = { playDetailsData: [], topArtists: null, settings: null, thumbnailSettings: null, currency: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_PLAYLIST_DETAILS_ACTION":
            return {
                ...state,
                playDetailsData: action?.payload?.audios ? action?.payload?.audios : action?.payload?.data,
                topArtists: action.payload.TopArtist,
                settings: action.payload.setting,
                thumbnailSettings: action.payload.ThumbnailSetting,
                currency: action.payload.currency,
                isLoading: false
            };
        case "GET_PLAYLIST_DETAILS_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const artistDetailsReducer = (state = { artistsDetailsData: [], topArtists: null, artistsAudios: null, settings: null, thumbnailSettings: null, currency: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_ARTISTS_DETAILS_ACTION":
            return {
                ...state,
                artistsDetailsData: action.payload.artist_details,
                topArtists: action.payload.TopArtist,
                artistsAudios: action.payload.artist_audios,
                settings: action.payload.setting,
                thumbnailSettings: action.payload.ThumbnailSetting,
                currency: action.payload.currency,
                isLoading: false
            };
        case "GET_ARTISTS_DETAILS_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const channelDetailsReducer = (state = { channelDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_CHANNEL_DETAILS_ACTION":
            return {
                ...state,
                channelDetailsData: action.payload,
                isLoading: false
            };
        case "GET_CHANNEL_DETAILS_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const contentDetailsReducer = (state = { contentDetailsData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_CONTENT_DETAILS_ACTION":
            return {
                ...state,
                contentDetailsData: action.payload,
                isLoading: false
            };
        case "GET_CONTENT_DETAILS_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const allHomePageReducer = (state = { homepage: [], getSource: [], getSourceType: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_ALLHOMEPAGE_REQUEST":
            return {
                ...state,
                homepage: [],
                isLoading: true,
                error: null
            };
        case "GET_ALLHOMEPAGE_ACTION":
            return {
                ...state,
                homepage: sortYearsInHomePageData(action.payload),
                getSource: (action.payload || []).map(item => URLGenerater(item.source) || []),
                getSourceType: (action.payload || []).map(item => URLGenerater(item.source_type) || []),
                isLoading: false,
            };
        case "GET_ALLHOMEPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
};
const movieHomePageReducer = (state = { moviePage: [], getSource: [], getSourceType: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "MOVIE_HOMEPAGE":
            return {
                ...state,
                moviePage: action.payload,
                getSource: (action.payload || []).map(item => URLGenerater(item.source) || []),
                getSourceType: (action.payload || []).map(item => URLGenerater(item.source_type) || []),
                isLoading: false,
            };
        case "MOVIE_HOMEPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
};
const showHomePageReducers = (state = { showPage: [], getSource: [], getSourceType: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "SHOW_HOMEPAGE":
            return {
                ...state,
                showPage: action.payload,
                getSource: (action.payload || []).map(item => URLGenerater(item.source) || []),
                getSourceType: (action.payload || []).map(item => URLGenerater(item.source_type) || []),
                isLoading: false,
            };
        case "SHOW_HOMEPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
};
const allThumbnailReducer = (state = { thumbnail: [], thumbnailTitle: null, thumbnailAge: null, thumbnailRating: null, thumbnailPublishedYear: null, thumbnailPlayButtonPath: null, thumbnailPlayButton: null, thumbnailTrailer: null, thumbnailReelsVideos: null, thumbnailFreeORCostLabel: null, thumbnailFeatured: null, thumbnailCategory: null, thumbnailDuration: null, thumbnailPublishedOn: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_THUMBNAIL_ACTION":
            return {
                ...state,
                thumbnail: action.payload,
                thumbnailTitle: action.payload[0].title,
                thumbnailAge: action.payload[0].age,
                thumbnailRating: action.payload[0].rating,
                thumbnailPublishedYear: action.payload[0].published_year,
                thumbnailPublishedOn: action.payload[0].published_on,
                thumbnailDuration: action.payload[0].duration,
                thumbnailCategory: action.payload[0].category,
                thumbnailFeatured: action.payload[0].featured,
                thumbnailFreeORCostLabel: action.payload[0].free_or_cost_label,
                thumbnailReelsVideos: action.payload[0].reels_videos,
                thumbnailTrailer: action.payload[0].trailer,
                thumbnailPlayButton: action.payload[0].play_button,
                thumbnailPlayButtonPath: action.payload[0].play_button_path,
                isLoading: false
            };
        case "GET_THUMBNAIL_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
}
const headerIndexReducer = (state = {
    headerMenus: null, themeMode: {
        body: '#1f222a',
        secondarycolor: '#2d3038',
        text: '#fdfcfc',
        button: '##000000',
        buttonText: '#fff',
        currentMode: 'dark',
        logoUrl: ''
    }, headerSetting: null, error: null, isLoading: true
}, action) => {
    switch (action.type) {
        case "GET_HEADERINDEX_ACTION":
            return {
                ...state,
                headerMenus: action.payload.Menu,
                headerindexall: action.payload,
                userDetails: action.payload.user_details,
                currentTheme: action?.payload?.current_theme?.slice(-1),
                // currentTheme: 2,
                themeMode: {
                    body: action.payload.theme_mode_details.bg_color,
                    text: action.payload.theme_mode_details.text_color,
                    button: action.payload.theme_mode_details.button_color,
                    buttonText: action.payload.theme_mode_details.button_text_color,
                    secondarycolor: action.payload.theme_mode_details.bg_secondary_color,
                    currentMode: action.payload.theme_mode_details.current_theme_mode,
                    logoUrl: action.payload.theme_mode_details.logo_url,
                    hoverCard: action.payload.theme_mode_details.enable_slider
                },
                headerSetting: action?.payload?.Setting,
                isLoading: false
            };
        case "GET_HEADERINDEX_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
}

const profileDetailsReducer = (state = { profileDetails: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_PROFILE_ACTION":
            return {
                ...state,
                profileDetails: action.payload,
                isLoading: false
            };
        case "GET_PROFILE_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
}

const viewAllPageReducer = (state = { viewAllData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_VIEWALLPAGE_REQUEST":
            return {
                ...state,
                isLoading: true,
                viewAllData: [],
                error: null
            };
        case "GET_VIEWALLPAGE_ACTION":
            return {
                ...state,
                viewAllData: action.payload,
                isLoading: false
            };
        case "GET_VIEWALLPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case "CLEAR_VIEWALLLIST":
            return {
                ...state,
                viewAllData: [],
                isLoading: false
            };
        default:
            return state;
    }
};
const languagePageReducer = (state = { languagePageList: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_LANGUAGE_PAGE_LIST_ACTION":
            return {
                ...state,
                languagePageList: action.payload,
                isLoading: false
            };
        case "GET_LANGUAGE_PAGE_LIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const liveCatPageReducer = (state = { liveCatPageList: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_LIVECAT_PAGE_LIST_ACTION":
            return {
                ...state,
                liveCatPageList: action.payload,
                isLoading: false
            };
        case "GET_LIVECAT_PAGE_LIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

const videoCatPageReducer = (state = { videoCatPageList: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_VIDEOCAT_PAGE_LIST_ACTION":
            return {
                ...state,
                videoCatPageList: action.payload,
                isLoading: false
            };
        case "GET_VIDEOCAT_PAGE_LIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const seriesCatPageReducer = (state = { seriesCatPageList: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SERIESCAT_PAGE_LIST_ACTION":
            return {
                ...state,
                seriesCatPageList: action.payload,
                isLoading: false
            };
        case "GET_SERIESCAT_PAGE_LIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const audioCatPageReducer = (state = { audioCatPageList: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_AUDIOCAT_PAGE_LIST_ACTION":
            return {
                ...state,
                audioCatPageList: action.payload,
                isLoading: false
            };
        case "GET_AUDIOCAT_PAGE_LIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

const viewAllPageIdReducer = (state = { viewallPageId: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_VIEWALLPAGEID_REQUEST":
            return {
                ...state,
                isLoading: true,
                viewallPageId: [],
                error: null
            };
        case "GET_VIEWALLPAGEID_ACTION":
            return {
                ...state,
                viewallPageId: action.payload,
                isLoading: false
            };
        case "GET_VIEWALLPAGEID_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const viewAllPageCatIdReducer = (state = { viewallPageCatId: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_VIEWALLPAGECATID_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null,
                viewallPageCatId: []
            };
        case "GET_VIEWALLPAGECATID_ACTION":
            return {
                ...state,
                viewallPageCatId: action.payload,
                isLoading: false
            };
        case "GET_VIEWALLPAGECATID_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const getwatchLaterListReducer = (state = { getwatchlaterData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_WATCHLATERLIST_ACTION":
            return {
                ...state,
                getwatchlaterData: action.payload,
                isLoading: false
            };
        case "GET_WATCHLATERLIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const getWishListReducer = (state = { getWishListData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_WISHLIST_ACTION":
            return {
                ...state,
                getWishListData: action.payload,
                isLoading: false
            };
        case "GET_WISHLIST_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const getMusicSettingsReducer = (state = { getMusicData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_MUSIC_ACTION":
            return {
                ...state,
                getMusicData: action.payload,
                isLoading: false
            };
        case "GET_MUSIC_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
const signUpSettingReducer = (state = { fieldRestrict: {}, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "SIGNUP-SETTINGS": return {
            ...state,
            fieldRestrict: action.payload,
            isLoading: false,
        }
        case "SIGNUP-SETTINGS_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}
const labelReducer = (state = { label: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_LABEL": return {
            ...state,
            label: action.payload,
            isLoading: false,
        }
        case "GET_LABEL_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export { labelReducer, signUpSettingReducer, showHomePageReducers, movieHomePageReducer, footerReducer, homeBannerReducer, viewallReducer, settingsReducer, filterReducer, siteThemeReducer, displayTranslateLanguageReducer, getUserTranslateLanguageReducer, videosDetailsReducer, seriesDetailsReducer, liveDetailsReducer, allHomePageReducer, artistDetailsReducer, channelDetailsReducer, contentDetailsReducer, allThumbnailReducer, headerIndexReducer, profileDetailsReducer, playDetailsReducer, viewAllPageReducer, languagePageReducer, viewAllPageIdReducer, liveCatPageReducer, viewAllPageCatIdReducer, videoCatPageReducer, seriesCatPageReducer, audioCatPageReducer, episodeDetailsReducer, seasonDetailsReducer, getwatchLaterListReducer, getWishListReducer, getMusicSettingsReducer }
