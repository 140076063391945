// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useInView } from 'react-intersection-observer';
// const LazyLoadImage = ({ src, alt, title, classNameOptional, imageSize, bgColor }) => {
//   const [ref, inView] = useInView({ triggerOnce: true });
//   const [isLoading, setIsLoading] = useState(true);
//   const handleImageLoad = () => { setIsLoading(false); };
//   const { t } = useTranslation();
//   return (
//     <>
//     <div className={`h-100 d-flex align-items-center justify-content-center overflow-hidden ${isLoading ? 'square' : ''}  ${classNameOptional}`} style={{ backgroundColor: bgColor }} ref={ref} >
//       {isLoading && (<span className='text-secondary loaderText p-2 theme-text-color mb-0 text-break position-absolute'>{t(title)} </span>)}
//       <img onLoad={handleImageLoad} src={inView ? src : ''} style={{ width: "100%", height: "100%" }} alt={alt} className={`position-relative d-block ${isLoading ? 'opacity-0 ' : 'opacity-100'}  ${imageSize}`} />
//     </div>
//     </>
//   );
// };
// export default LazyLoadImage;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

const LazyLoadImage = ({ src, alt, title, classNameOptional, imageSize, bgColor, fallbackSrc }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false); // State for error handling
  const { t } = useTranslation();

  // Preload image immediately when the component is mounted
  useEffect(() => {
    if (src) {
      // Preload the image before the user scrolls to it
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = src;
      link.as = 'image';
      link.fetchpriority = 'high';  // Prioritize the image load
      document.head.appendChild(link);

      // Cleanup on component unmount
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [src]); // Run once when the component mounts

  // Handle image load
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  // Handle image error
  const handleImageError = () => {
    setIsLoading(false);
    setImageError(true); // Set error state if image fails to load
  };

  return (
    <div
      className={`h-100 d-flex align-items-center justify-content-center overflow-hidden ${isLoading ? 'square' : ''} ${classNameOptional}`}
      style={{ backgroundColor: bgColor }}
      ref={ref}
    >
      {isLoading && (
        <span className='text-secondary loaderText p-2 theme-text-color mb-0 text-break position-absolute'>
          {t(title)}
        </span>
      )}
      {/* Always set the src to avoid delays in loading the image */}
      <img
        onLoad={handleImageLoad}
        onError={handleImageError} // Handle image loading errors
        src={imageError ? fallbackSrc : src}  // Use fallback image on error
        style={{ width: '100%', height: '100%' }}
        alt={alt}
        className={`position-relative d-block ${isLoading ? 'opacity-0 ' : 'opacity-100'} ${imageSize}`}
      />
    </div>
  );
};

export default LazyLoadImage;
