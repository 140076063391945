import React, { useEffect } from 'react';
import BannerOverlay from './BannerOverlay';
import './BannerImage.css';
import BannerOverlayThemeTwo from './BannerOverlayThemeTwo';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';

const BannerImage = ({ data, currentTheme, page, siteTheme }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5, // Images load when 50% visible
    });


    useEffect(() => {
        if (data?.slider_image) {
            const preloadLink = document.createElement('link');
            preloadLink.rel = 'preload';
            preloadLink.as = 'image';
            preloadLink.href = data?.slider_image;
            preloadLink.href = data?.Player_image_url;
            document.head.appendChild(preloadLink);
            return () => document.head.removeChild(preloadLink);
        }
    }, [data]);


    return (
        <div
            ref={ref}
            className={`${currentTheme === 1 || page === "movie"
                ? "bannerContainer"
                : "bannerWrapper"
                } w-100 h-100 ${data?.id} trailerNot`}
        >
            {inView && (
                <img
                    src={data?.slider_image || data?.Player_image_url}
                    srcSet={`
        ${data?.slider_image || data?.Player_image_url},
        ${data?.slider_image || data?.Player_image_url},
        ${data?.slider_image || data?.Player_image_url}
    `}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt={data?.alt || "Banner"}
                    className="banner-image"
                />

            )}
            {currentTheme == 1 && (
                <BannerOverlay
                    data={data}
                    type="image"
                    page={page}
                    translateCheckout={siteTheme?.translate_checkout}
                />
            )}
            {currentTheme == 2 && (
                <BannerOverlayThemeTwo
                    data={data}
                    type="image"
                    page={page}
                    translateCheckout={siteTheme?.translate_checkout}
                    className={page === "movie" ? "px-2 px-md-3 px-lg-5 py-2 py-md-3 py-lg-5" : ""}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentTheme: state.get_siteTheme_Reducer.currentTheme,
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
});

export default connect(mapStateToProps)(BannerImage);



// import React from 'react';
// import BannerOverlay from './BannerOverlay';
// import BannerOverlayThemeTwo from './BannerOverlayThemeTwo';
// import { connect } from 'react-redux';
// import { useInView } from 'react-intersection-observer';

// const BannerImage = ({ data, currentTheme, page, siteTheme }) => {

//     const [ref, inView] = useInView({
//         triggerOnce: true,
//     });

//     const backgroundImageStyle = inView ? { backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.5) 50%, transparent), url(${data?.slider_image ? data?.image_url : data?.Player_image_url})` } : { backgroundColor: '' };

//     return (
//         <div ref={ref} className={`${currentTheme == 1 || page == "movie" ? "bannerContainer" : "bannerWrapper"}  w-100 h-100 ${data?.id} trailerNot`} key={data.id} style={backgroundImageStyle}>
//             {currentTheme == 1 && <BannerOverlay data={data} type="image" page={page} translateCheckout={siteTheme?.translate_checkout} />}
//             {currentTheme == 2 && (<BannerOverlayThemeTwo data={data} type="image" page={page} translateCheckout={siteTheme?.translate_checkout} className={page == "movie" ? "px-2 px-md-3 px-lg-5 py-2 py-md-3 py-lg-5 " : ""} />)}
//         </div>
//     );
// };

// const mapStateToProps = (state) => ({
//     currentTheme: state.get_siteTheme_Reducer.currentTheme,
//     siteTheme: state.get_siteTheme_Reducer.siteTheme,
// });
// export default connect(mapStateToProps)(BannerImage);
