import React, { useEffect, useRef, useState } from "react";
import videojs from 'video.js';
import { useNavigate, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import { FaPlay, FaPause } from 'react-icons/fa';
import { GrForwardTen, GrBackTen } from "react-icons/gr";
import AdInformationCard from "./AdInformationCard";
import { useTranslation } from "react-i18next";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { wishListAction } from "../../Pages/CommonMethods/CommonMethods";
import { getWishListAction } from "../../Redux/Actions/apiActions";
import { useDispatch } from "react-redux";
import 'videojs-hls-quality-selector';
import { useMyContext } from "../Layout/CommonProvider";

function convertTimeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
export const VideosJs = ({ videoDetailsData, options, onReady, settings, imaOptions, audioTracks, wishListCheckValue }) => {

  let freeDurationInSeconds = null;
  if (videoDetailsData?.free_duration_status == 1) {
    freeDurationInSeconds = convertTimeToSeconds(videoDetailsData?.free_duration_time);
  }
  const [hasReachedTimeLimit, setHasReachedTimeLimit] = useState(false);
  const [showPurchasePage, setShowPurchasePage] = useState(false);
  const getLastViewedVideo = videoDetailsData?.continue_watching_exists?.current_time;
  const { t } = useTranslation();
  const { lang, slug } = useParams();
  const { navigateTranslateChecker } = useMyContext()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState({ skipButton: false, recapButton: false, cardButton: false, relatedVideo: false });
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [bufferedTime, setBufferedTime] = useState(0);
  const [totalSkipForward, setTotalSkipForward] = useState(0);
  const [totalSkipBackward, setTotalSkipBackward] = useState(0);
  const TimeConverter = (data) => {
    if (!data || typeof data !== "string" || !data.includes(":")) {
      return 0;
    }
    const [hours, minutes, seconds] = data.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.error("Invalid time components:", { hours, minutes, seconds });
      return 0;
    }
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };
  const handleRecap = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime - TimeConverter(videoDetailsData?.recap_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, recapButton: false, }));
  };
  const handleSkip = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime + TimeConverter(videoDetailsData?.skip_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, skipButton: false, }));
  }
  const handleFullscreen = () => {
    const player = playerRef.current;
    if (player) {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      } else {
        player.requestFullscreen();
      }
    }
  };
  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      const player = videojs(videoRef.current, options, () => {
        playerRef.current = player;

        // Clear any existing text tracks to prevent duplicates
        const existingTracks = player.remoteTextTracks();
        for (let i = existingTracks.length - 1; i >= 0; i--) {
          player.removeRemoteTextTrack(existingTracks[i]);
        }

        // Add subtitles
        videoDetailsData?.Subtitle?.forEach((subtitle) => {
          player.addRemoteTextTrack({
            kind: 'captions',
            label: subtitle?.subtitle_language,
            srclang: subtitle?.shortcode,
            src: subtitle?.subtitle_url,
          }, false);
        });
        player.loadingSpinner.removeClass('vjs-loading-spinner');
        player.loadingSpinner.addClass('vjs-loading-spinner-myvideo');
        // const handleContextMenu = (e) => {
        //   e.preventDefault();
        // };
        // videoRef.current.addEventListener('contextmenu', handleContextMenu);
        const toggleButtons = () => {
          const currentTime = player.currentTime();
          const startShowTimeSkip = TimeConverter(videoDetailsData?.skip_start_time);
          const endShowTimeSkip = TimeConverter(videoDetailsData?.skip_end_time);
          const startShowTimeRecap = TimeConverter(videoDetailsData?.recap_start_time);
          const endShowTimeRecap = TimeConverter(videoDetailsData?.recap_end_time);
          const startShowTimeCard = TimeConverter(videoDetailsData?.url_linktym);
          const endShowTimeCard = TimeConverter(videoDetailsData?.urlEnd_linksec);
          setShowButton((prevState) => ({
            ...prevState,
            skipButton: currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
            recapButton: currentTime >= startShowTimeRecap && currentTime <= endShowTimeRecap,
            cardButton: currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
            relatedVideo: false,
          }));
        };
        const calculateBufferedTime = () => {
          const buffered = player.buffered();
          let totalBufferedTime = 0;
          for (let i = 0; i < buffered.length; i++) {
            totalBufferedTime += buffered.end(i) - buffered.start(i);
          }
          setBufferedTime(totalBufferedTime);
        };
        if (options?.sources && options?.sources?.[0]?.src?.endsWith('.m3u8')) {
          if (player && player?.hlsQualitySelector) {
            console.log('HLS quality selector enabled');
            player?.hlsQualitySelector({
              displayCurrentQuality: true,
            });
          }
          // player.ready(() => {
          //   console.log("playerrrrr",player);
          //   const qualityLevels = player.qualityLevels();
          
          //   qualityLevels.on('addqualitylevel', () => {
          //     for (let i = 0; i < qualityLevels.length; i++) {
          //       const level = qualityLevels[i];
          //       console.log(`Quality Level: ${level.height}p`);
          //     }
          //   });
          
          //   // Set a specific quality level (optional)
          //   qualityLevels[0].enabled = true;
          // });
          // console.log('HLS quality selector enabled');
        }
        player.on('fullscreenchange', () => setIsFullscreen(player.isFullscreen()));
        player.on('play', () => setIsPlaying(true));
        player.on('pause', () => setIsPlaying(false));
        player.on('userinactive', () => {
          const playPauseButton = document.querySelector('.play-skip-button-center');
          if (playPauseButton) {
            playPauseButton.style.display = 'none';
          }
        });
        player.on('useractive', () => {
          const playPauseButton = document.querySelector('.play-skip-button-center');
          if (playPauseButton) {
            playPauseButton.style.display = 'flex';
          }
        });

        player.on('progress', calculateBufferedTime);
        player.on('timeupdate', toggleButtons);
        player.on('ended', () => {
          setShowButton((prevState) => ({ ...prevState, relatedVideo: true }));
        });
        player.on('timeupdate', () => {
          setCurrentTime(player.currentTime());
          setPlayedSeconds(player.currentTime());
          if (player.currentTime() >= freeDurationInSeconds && !hasReachedTimeLimit) {
            setHasReachedTimeLimit(true);
            setShowPurchasePage(true);
          }
        });
        player.on('loadedmetadata', () => {
          setDuration(player.duration());
          if (videoDetailsData?.users_free_duration_status == false) {
            if (getLastViewedVideo) {
              player.currentTime(getLastViewedVideo);
            }
            const updateProgressControl = () => {
              const duration = player.duration();
              const watchedPercentage = (getLastViewedVideo / duration) * 100;
              const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
              const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');
              if (playProgress && sliderBar) {
                playProgress.style.width = `${watchedPercentage}%`;
                sliderBar.style.width = `${watchedPercentage}%`;
              }
            };
            updateProgressControl();
          }
        });
        player.on('ended', () => {
          setShowButton((prevState) => ({ ...prevState, relatedVideo: true }));
        });

        return () => {
          // videoRef.current.removeEventListener('contextmenu', handleContextMenu);s
          if (player) {
            player.dispose(); // Clean up player on unmount
          }
        };
      });

      const advertisement = videoDetailsData?.get_advertisement;
      const {
        pre_advertisement: CheckPreAds,
        post_advertisement: CheckPostAds,
        mid_advertisement: midrollAds,
        mid_advertisement_sequence_time: midrollInterval,
      } = advertisement;
      
      // const midrollInterval = 300;
      // const midrollAds = ['https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='];
      // const CheckPreAds = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
      // const CheckPostAds = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';

      const loadGoogleIMASDK = () => {
        if (!window.google || !window.google.ima) {
          const script = document.createElement("script");
          script.src = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";
          script.async = true;
          script.onload = () => {
            console.log("IMA SDK Loaded");
            initializeIMAPlugin();
          };
          script.onerror = () => console.error("Failed to load IMA SDK");
          document.body.appendChild(script);
        } else {
          console.log("IMA SDK already loaded");
          initializeIMAPlugin();
        }
      };
      
      const initializeIMAPlugin = () => {
        if (player && typeof player.ima === 'function') {
          player.ima({
            adTagUrl: '',
            showControlsForAds: true,
            debug: false,
            adsRenderingSettings: {
              loadVideoTimeout: 15000,
            }
          });
          player.ima.initializeAdDisplayContainer();
          initializePlayer(CheckPreAds, CheckPostAds, midrollAds, midrollInterval);
        }
      };

      
      if( CheckPreAds != null || CheckPostAds != null || midrollAds.length < 0 ){
        loadGoogleIMASDK();
      }
    }
  }, [videoDetailsData, hasReachedTimeLimit]);

  const initializePlayer = async ( CheckPreAds, CheckPostAds, midrollAds, midrollInterval ) => {
    const player = videojs(videoRef.current);

    // Ads Marker
    player.on("loadedmetadata", function () {
      const markers = [];
      const total = player.duration();

      if (total != 'Infinity') {
        if (CheckPreAds) markers.push({ time: 0 });

        if (midrollInterval && midrollAds.length > 0 ) {
          for (let time = midrollInterval; time < total; time += midrollInterval) {
            markers.push({ time });
          } 
        }

        if (CheckPostAds) markers.push({ time: total });


        const markerSpace = player.controlBar.progressControl.el();
        markers.forEach((marker) => {
          const time = marker.time;   
          let left = time == 0 ? (marker.time / total) * 100 + 0.5 + "%" : time == total ? (marker.time / total) * 100 - 1 + "%" : (marker.time / total) * 100 + "%"
          const el = document.createElement("div");
          el.className = "vjs-marker";
          el.style.left = left;
          el.dataset.time = time;
          el.addEventListener("click", () => {
            player.currentTime(time);
          });
          markerSpace.appendChild(el);
        });
      }
    });

    var prerollTriggered = false;
    let lastMidrollTime = 0;

    if (CheckPreAds && currentTime == 0 ) {
      player.ima.changeAdTag(CheckPreAds);
    }

    player.on("timeupdate", () => {
      const currentTime = player.currentTime();
      const duration = player.duration();

      if (midrollAds && currentTime - lastMidrollTime >= midrollInterval) {
        lastMidrollTime = currentTime;
        player.ima.changeAdTag(midrollAds[0]);
        player.ima.requestAds();
      }

      if (CheckPostAds && currentTime >= duration - 2) {
        player.ima.changeAdTag(CheckPostAds);
        player.ima.requestAds();
      }
    });

    player.on("aderror", function (event) {
      console.error("Ad error: ", event);
      player.play();
    });

    player.on("adend", function () {
      prerollTriggered = false;
      player.play();
    });
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  };
  //FreeDuration
  useEffect(() => {
    if (videoDetailsData?.users_free_duration_status === true &&
      videoDetailsData?.users_video_visibility_status == true && hasReachedTimeLimit) {
      setShowPurchasePage(true)
      navigate(navigateTranslateChecker(`/purchase/${slug}`));
    }
  }, [videoDetailsData, hasReachedTimeLimit, lang, slug]);

  const newCurrentTime = Math.floor(currentTime);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', region: '', country: '', latitude: '', longitude: '', ip: '', });
  useEffect(() => {
    if (duration > 0) {
      const percentage = (playedSeconds / duration) * 100;
      setWatchPercentage(percentage);
    }
  }, [playedSeconds, duration]);
  const calculatePercentage = watchPercentage.toFixed(2);
  const userRole = user_role ? user_role : '9';
  const SaveLastViewedVideo = async () => {
    try {
      const data = {
        Login_user_type: userRole,
        Login_device_type: 'web',
        Login_user_id: user_id,
        source_type_id: 1,  //For Videos
        source_id: videoDetailsData?.id,
        source_type: 1,
        current_time: newCurrentTime,
        watch_percentage: `${calculatePercentage}%`,
        skip_time: '',
        country_name: locationData?.country,
        region_name: locationData?.region,
        city_name: locationData?.city
      }
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/continue-watching-recent-view-store-update`, data);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        setTimeout(() => {
          player.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }, 100);
      } else {
        player.pause();
      }
    }
  };

  const closeButton = () => {
    try {
      SaveLastViewedVideo();
      const { access_free } = settings;
      const isGuest = !localStorage.getItem("role");
      const { access, slug } = videoDetailsData || {};
      if (window.history.state && window.history.state.idx > 0) {
        window.history.back();
      } else {
        if (isGuest && access == "guest" && access_free == 0) {
          navigate('/');
        } else {
          navigate(navigateTranslateChecker(`/videos/${slug}`));
        }
      }
    } catch (error) {
      console.error('Error in backButton:', error);
    }
  };
  const skipForward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + duration);
    setTotalSkipForward((prev) => {
      const newTotal = prev + duration;
      // Check if skip forward equals skip backward
      if (newTotal === totalSkipBackward) {
        setTotalSkipForward(0);
        setTotalSkipBackward(0);
      }
      return newTotal;
    });
  };

  const skipBackward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - duration);
    setTotalSkipBackward((prev) => {
      const newTotal = prev + duration;
      // Check if skip forward equals skip backward
      if (newTotal === totalSkipForward) {
        setTotalSkipForward(0);
        setTotalSkipBackward(0);
      }
      return newTotal;
    });
  };

  const [showSlider, setShowSlider] = useState(false);
  const toggleSlider = () => {
    setShowSlider(prevShowSlider => !prevShowSlider);
    setShowButton({
      relatedVideo: false
    })
    const swiperContainer = document.querySelector('.swiper-container');
    if (swiperContainer) {
      swiperContainer.style.display = showSlider ? 'block' : 'none';
    }
  };

  useEffect(() => {
    const player = videojs(videoRef.current, {}, function onPlayerReady() {
      player.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    });
    if (videoRef.current) {
      player.on('ended', () => {
        if (!wishListCheckValue) {
          wishListAction(null, null, null, videoDetailsData?.id, 1);
          dispatch(getWishListAction());
        }
      });
    }
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [wishListCheckValue]);
  return (
    <div data-vjs-player className="position-relative" style={{ "overflow": "hidden" }}>
      <div className="d-flex align-items-center position-relative px-3 py-2 gap-3">
        <AiOutlineClose style={{ "fontSize": "35px", "fontWeight": "bold", "fill": "white", "cursor": "pointer" }} onClick={closeButton} />
        <h3 className="theme-text-color">{t(videoDetailsData?.title)}</h3>
      </div>
      <div className="position-absolute col-6 play-skip-button-center align-items-center justify-content-between">
        <button className=" bg-transparent" onClick={() => skipBackward(10)}>
          <GrBackTen style={{ fontSize: "38px" }} />
        </button>
        <button className=" bg-transparent" onClick={togglePlayPause}>
          {isPlaying ? <FaPause style={{ fontSize: "35px" }} /> : <FaPlay style={{ fontSize: "35px" }} />}
        </button>
        <button className=" bg-transparent" onClick={() => skipForward(10)}>
          <GrForwardTen style={{ fontSize: "38px" }} />
        </button>
      </div>
      <button className="custom-fullscreen-button bg-transparent" onClick={handleFullscreen}>
        {isFullscreen ? <BsFullscreenExit style={{ fontSize: "28px" }} /> : <BsArrowsFullscreen style={{ fontSize: "28px" }} />}
      </button>
      <div className="d-flex align-items-center position-absolute skipRecapWrapper gap-3">
        {showButton?.skipButton && (<button onClick={handleSkip} className="skipBtn bg-transparent rounded-2 border border-light px-5 py-3" > <span className="theme-text-color skipText">Skip Intro </span> </button>)}
        {/* {showButton?.recapButton && (<button onClick={handleRecap} className="skipBtn bg-transparent  rounded-2 border border-light px-3 py-2" > <VscDebugRestart className="theme-text-color" style={{ fontSize: "30px" }} /> </button>)} */}
      </div>
      {showButton?.cardButton && <AdInformationCard title={videoDetailsData?.title} link={videoDetailsData?.url_link} />}
      {/* {showButton?.relatedVideo && <EndCard relatedVideo={videoDetailsData?.title} />} */}
      <video data-vjs-player ref={videoRef} className="vjs-big-play-centered vjs-theme-city my-video-dimensions video-js vjs-play-control customVideoPlayer" />
      {/* {showButton?.relatedVideo && <RelatedVideosSwiper relatedVideo={videoDetailsData?.title} onClick={toggleSlider} />} */}
    </div>
  );
};
export default VideosJs;