import "./Edit_new_audio.css";
 
import loadings from "../../../assets/gif/loading.gif";

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
// import JoditEditor from "jodit-react";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { toast } from "react-toastify";
import { FcOk, FcHighPriority, FcFile } from "react-icons/fc";
import { BsFiletypeXlsx } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminSaveHeader from "../../../components/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageBox from "../../../components/MessageBox";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageSubHeading from "../../CommonComponents/MessageSubHeading";
import AdminCustomButton from "../../../components/adminCommonComponents/AdminCustomButton";

function Edit_new_audio() {
  const { id } = useParams();
  const [getSuccessLyrics, setSuccessLyrics] = useState(null);
  const [getDangerLyrics, setDangerLyrics] = useState(null);
  const [getLyrics, setLyrics] = useState(null);
  const [getExampleUrl, setExampleUrl] = useState(null);
  const AudioRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    ppv_status: "",
    ppv_price: "",
    type: "",
    access: "",
    album_id: "",
    artists: "",
    rating: "",
    details: "",
    description: "",
    active: "",
    status: "",
    draft: "",
    featured: "",
    albumname: "",
    banner: "",
    duration: "",
    views: "",
    year: "",
    age_restrict: "",
    mp3_url: "",
    image: "",
    player_image: "",
    tv_image: "",
    search_tags: "",
    ios_ppv_price: "",
    uploaded_by: "",
    image_url: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
  });
  const [editname, setEditname] = useState({ title: "", });
  const [data, setData] = useState([]);
  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [album, setAlbum] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [fetchOverlay, setFetchOverlay] = useState(false)
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");
  const [ppvPriceOfficial, setppvPriceOfficial] = useState()
  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue(ppvPriceOfficial);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    // alert(event.target.value);
  };
  const handleChangeyear = (event) => {
    const value = event?.target?.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser({ ...editUser, year: value });
    }
  };
  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");
    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);
    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };
  const handleInputChangetag = (input) => {
    setNewTag(input);
  };
  const handleInputKeyPress = (event) => {
    if (event.key == "Enter" || event.key == " ") {
      event.preventDefault(); // Prevent the default behavior (adding space or newline)
      // Only add the tag if it's not empty
      if (newTag.trim() !== "") {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    }
  };

  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, details: e });
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );

  const lyricsUpload = new FormData();
  lyricsUpload.append("lyrics", getLyrics);
  lyricsUpload.append("audio_id", id);
  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit.set("publish_time", timeValue);
    formDatapublishedit.set("publish_type", publishType);
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    albumname: editUser?.albumname,
    // ...editInputvalue,
    // other form data properties...
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setFetchOverlay(true)
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      setExampleUrl(response?.data?.sample_audio_lyrics);
      const data = response?.data?.audios[0]?.selected_block_countries;
      const dataavailable_country =
        response?.data?.audios[0]?.available_countries;
      const dataartists = response?.data?.audios[0]?.selected_artists;
      const datacategories = response?.data?.audios[0]?.selected_categories;
      const datalanguages = response?.data?.audios[0]?.selected_Language;
      const dataAge_Restrict = response?.data?.audios[0]?.selected_Age_Restrict;
      // const datarelated_video = response?.data?.selected_related_video;
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      setLoading(false);
      var globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item.country,
        label: item.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.artist_id,
        label: item.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.category_id,
        label: item.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.language_id,
        label: item.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);
      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setTags(
        response?.data?.audios[0]?.search_tags
          ?.split(",")
          ?.map((tag) => tag.trim())
      );
      setFetchOverlay(false)
    } catch (error) {
      console.error(error);
      setLoading(false);
      setFetchOverlay(false)
    }
    try {
      setFetchOverlay(true)
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      setAlbum(response?.data?.audio_albums);
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      setLivestream_source(response?.data?.Livestream_source);
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.age,
      }));
      setLoading(false);
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setFetchOverlay(false)
    } catch (error) {
      console.error(error);
      setLoading(false);
      setFetchOverlay(false)
    }
  };
  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option?.value);
    setFormData({ ...formData, });
    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (selectedOptionsavailable_country) => {
    const available_country = selectedOptionsavailable_country?.map((option) => option.value);
    setFormData({ ...formData, });
    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);
    setFormData({ ...formData });
    setSelectedValuesartists(selectedOptionsartists);
  };
  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);
    setFormData({
      ...formData,
    });
    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };
  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map((option) => option.value);
    setFormData({ ...formData, });
    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);
    setFormData({ ...formData, });
    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map((option) => option.value);
    setFormData({ ...formData, });
    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };
  const convertToTitleCase = (str) => { return str?.split("_")?.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))?.join(" "); };
  const formattedDatatype = convertToTitleCase(editUser?.type);
  const handleAdsPositionChange = (selectedValue5) => { setSelectedAdsPosition(selectedValue5); };
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null)
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audios[0];
      setEditUser(res);
      setEditname(res);
      setallliveuseraccess(res.access)
      setInputValue(res.ppv_price)
      setppvPriceOfficial(res.ppv_price)
      setPublishType(res?.publish_type);
      setPublishTime(res?.publish_time);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);
  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);
  const [validationMessagecategory, setValidationMessagecategory] = useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] = useState(true);
  const [validationMessagelanguages, setValidationMessagelanguages] = useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] = useState(true);
  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const handleUpdate = async (e) => {
    e.preventDefault();
    let focusInputRef = null;
    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length == 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (!selectedValueslanguages || selectedValueslanguages?.length == 0) {
      setValidationMessagelanguages("Please select at least one Language.");
      setIsValidationHiddenlanguages(false);
      focusInputRef = languageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    const updatedTagsString = tags?.join(", ");
    const editInputvalue = {
      title: editUser?.title,
      slug: editUser?.slug,
      ppv_status: editUser?.ppv_status,
      ppv_price: inputValue,
      type: editUser?.type,
      access: editUser?.access,
      album_id: editUser?.album_id,
      artists: editUser?.artists,
      rating: editUser?.rating,
      details: editUser?.details,
      description: editUser?.description,
      active: editUser?.active,
      status: editUser?.status,
      draft: editUser?.draft,
      featured: editUser?.featured,
      banner: editUser?.banner,
      ads_position: editUser?.ads_position,
      duration: editUser?.duration,
      views: editUser?.views,
      year: editUser?.year,
      albumname: editUser?.albumname,
      age_restrict: editUser?.age_restrict,
      mp3_url: editUser?.mp3_url,
      image: editUser?.image,
      player_image: editUser?.player_image,
      tv_image: editUser?.tv_image,
      search_tags: updatedTagsString,
      ios_ppv_price: editUser?.ios_ppv_price,
      uploaded_by: editUser?.uploaded_by,
      image_url: editUser?.image_url,
      Player_thumbnail: editUser?.Player_thumbnail,
      TV_Thumbnail: editUser?.TV_Thumbnail,
      pre_ads_category: editUser?.pre_ads_category,
      mid_ads_category: editUser?.mid_ads_category,
      post_ads_category: editUser?.post_ads_category,
      languages_id: selectedValueslanguages?.map((option) => option.value),
      artist_id: selectedValuesartists?.map((option) => option.value),
      country: selectedValues?.map((option) => option.value),
      category_id: selectedValuescategories?.map((option) => option.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let audioApiStatus = false;

    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    if (getDangerLyrics) {
      AudioRef.current.focus();
      return;
    }
    setProcessing(true);
    setShowOverlay(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();
      if (resjson.status == true) {
        firstApiStatus = true;
        resultapi = resjson;
        const formData = new FormData();
        formData.append("audio_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("tv_image", selectedFile3);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/Image-upload`,
          formData,
          { headers: headers }
        );
        const formDataseo = new FormData();
        formDataseo.append("audio_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);
        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/SEO`,
          formDataseo,
          { headers: headers }
        );
        const audioLyricsResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/lyrics-upload`,
          lyricsUpload,
          { headers }
        );
        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
        if (audioLyricsResponse.data.status == true) {
          audioApiStatus = true;
        }
      } else if (resjson.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      audioApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      audioApiStatus
    ) {
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        //   console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  function allaudiosuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="">
            <div className="row mx-0">
              <MessageLabel text="PPV Price" className="ps-0" />
              <div className="col-8 ps-0">
                <MessageSubHeading text="Apply PPV Price from Global Settings?" />
              </div>
              <div className="col-4 text-end pe-0">
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
            </div>
            <input
              type="number"
              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3"
              placeholder="PPV Price"
              id="ppv_price"
              name="ppv_price"
              value={inputValue}
              onChange={handleInputChange}
            />
            <MessageLabel text="IOS PPV Price" />
            <MessageSubHeading text="Apply IOS PPV Price from Global Settings?" />
            <div className="panel-body ppv_price_ios mt-2">
              <select
                name="ios_ppv_price"
                id="ios_ppv_price"
                onChange={handleInput}
                value={editUser?.ios_ppv_price}
                className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
              >
                {inapppurchase?.map((item, key) => (
                  <option value={item?.product_id} className="theme-bg-color theme-text-color">{item?.plan_price}</option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const handleLyrics = async (event) => {
    setSuccessLyrics(null);
    setDangerLyrics(null);
    const selectedFile = event.target.files;
    if (selectedFile) {
      const newFile = selectedFile[0];
      setLyrics(newFile);

      try {
        const lyricsDatas = new FormData();
        lyricsDatas.append("lyrics", newFile);
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/lyrics-validation`, lyricsDatas, { headers });
        let result = response?.data;
        if (result.status == true) {
          setSuccessLyrics(result.message);
        } else {
          setSuccessLyrics(null);
          setDangerLyrics(result.message);
        }
      } catch (err) {
        var errorMessage = err.response.data.message;
        setSuccessLyrics(null);
        setDangerLyrics(errorMessage);
      }
    }
  };
  const handleClearFile = () => {
    setLyrics(null);
    setSuccessLyrics(null);
    setDangerLyrics(null);
  };

  return (
    <>
      {showOverlay && <ResponseLoader />}
      {fetchOverlay && <ResponseLoader text="Fetching" />}
      <AdminSaveHeader heading="Edit Audio" saveText="Update Audio" saveMethod={handleUpdate} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text={editname?.title} />
            <MessageLabel text="Title" className="mandatorySimple" />
            <MessageSubHeading text="Add the audio title in the textbox below" />
            <input type="text" id="title" name="title" value={editUser?.title} onChange={handleInput} placeholder="Title" ref={titleInputRef} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />
            {!isValidationHiddentitle && (<MessageBox text={`${validationMessagetitle}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text='Slug' />
            <MessageSubHeading text="Add the Audio slug" />
            <input type="text" name="slug" id="slug" onChange={handleInput} value={editUser?.slug} placeholder="slug" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            {!isValidationHiddenslug && (<MessageBox text={`${validationMessageslug}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Short Description" />
            <MessageSubHeading text="Add a short description of the audio below" />
            <textarea id="description" name="description" placeholder="Description" onChange={handleInput} value={editUser?.description} className="rs-input short-description theme-bg-color-secondary custom-placeholder theme-text-color border-0  mb-3" />

            <MessageLabel text="Audio Details, Links, and Info" />
            {/* <JoditEditor value={editUser?.details} onChange={(e) => { handleChangeFirstdetails(e); }} className="rs-input custom-placeholder theme-bg-color-secondary  custom-placeholder mb-3 theme-text-color border-0" /> */}

            <MessageLabel text="Duration" />
            <MessageSubHeading text="Enter the audio duration in the following format (Hours : Minutes : Seconds)" />
            <input type="text" name="duration" value={editUser?.duration} maxLength="8" placeholder="duration" onChange={handleInputdata} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />

            <MessageLabel text="Year" />
            <MessageSubHeading text="Audio Released Year" />
            <input type="number" name="number" value={editUser?.year} placeholder="year" onChange={handleChangeyear} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />

          </Wrapper>
          <Wrapper>
            <MessageHeading text='Organize' />
            <MessageLabel text="Audio Ratings" />
            <MessageSubHeading text="IMDb Ratings 10 out of 10" />
            <select id="rating" name="rating" size="lg" onChange={handleInput} value={editUser?.rating} className="form-select custom-placeholder  mb-3 theme-bg-color-secondary theme-text-color border-0">
              {Array.from({ length: 10 }).map((data, index) => { return <option value={index + 1} className="theme-bg-color theme-text-color">{index + 1}</option> })}
            </select>

            <MessageLabel text="Age Restrict" />
            <MessageSubHeading text="Select a Audio age Below" />
            <select id="age_restrict" name="age_restrict" onChange={handleInput} value={editUser?.age_restrict} className="form-select mb-3 custom-placeholder  theme-bg-color-secondary theme-text-color border-0" >
              <option value="" className="theme-bg-color theme-text-color"> Choose an Age</option>
              {age_restrictdata?.map((item) => (<option value={item.id} className="theme-bg-color theme-text-color">{item.slug}</option>))}
            </select>

            <MessageLabel text="Album" />
            <MessageSubHeading text="Select A Audio Album Below" />
            <select id="album_id" name="album_id" onChange={handleInput} value={editUser?.album_id} className="form-select custom-placeholder mb-3 theme-bg-color-secondary theme-text-color border-0"  >
              <option value="" className="theme-bg-color theme-text-color">Choose a Album</option>
              {album?.map((item, key) => (<option value={item?.id} className="theme-bg-color theme-text-color">{item?.albumname}</option>))}
            </select>

            <MessageLabel text="Cast and Crew" />
            <MessageSubHeading text="Add artists for the audio below" />
            <Select options={optionsartists} isMulti onChange={handleSelectChangeartists} value={selectedValuesartists} className="theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3" />

            <MessageLabel text="Category" className='mandatorySimple' />
            <MessageSubHeading text="Select a Audio Category Below" />
            <Select options={optionscategories} isMulti onChange={handleSelectChangecategories} value={selectedValuescategories} ref={categoryInputRef} className="theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3" />
            {!isValidationHiddencategory && (<MessageBox text={`${validationMessagecategory}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Language" />
            <MessageSubHeading text="Select a Audio Language Below" />
            <Select options={optionslanguages} isMulti onChange={handleSelectChangelanguages} value={selectedValueslanguages} ref={languageInputRef} className="theme-bg-color-secondary custom-placeholder theme-text-color mb-3 border-0" />
            {!isValidationHiddenlanguages && (<MessageBox text={`${validationMessagelanguages}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Blocked Country" />
            <MessageSubHeading text="Select a country with blocked audio features." />
            <Select options={options} isMulti onChange={handleSelectChange} value={selectedValues} className="custom-placeholder theme-bg-color-secondary theme-text-color mb-3" />

            <MessageLabel text="Available Country" />
            <MessageSubHeading text="Select a country with available audio features." />
            <Select options={optionsavailable_country} isMulti onChange={handleSelectChangeavailable_country} value={selectedValuesavailable_country} className="custom-placeholder theme-bg-color-secondary theme-text-color  border-0" />

          </Wrapper>

          <Wrapper>
            <MessageHeading text="Search Tags" />
            <MessageLabel text="You don't have any search keywords." />
            <TagsInput value={tags ? tags : ["", ""]} onChange={handleTagsChange} inputValue={newTag} onChangeInput={handleInputChangetag} inputProps={{ onKeyPress: handleInputKeyPress }} className="rs-input form-control-lg theme-bg-color-secondary  border-0  theme-text-color" />
          </Wrapper>

        </LeftWrapper >
        <RightWrapper>

          {editUser?.mp3_url ? (
            <Wrapper>
              <MessageHeading text={`Audio ${formattedDatatype}`} />
              {/* <ReactPlayer url={editUser?.mp3_url} controls={true} width="100%" height="50px" /> */}
            </Wrapper>
          ) : null}

          <Wrapper>
            <MessageHeading text='Audio Lyrics' />
            <MessageLabel text="Upload Audio Lyrics" />
            <div className="d-flex align-items-cneter gap-2 ">
              <MessageBox classname="theme-text-color fw-bold opacity-75 " text="Ex:xlsx" />
              <Link to={getExampleUrl} className="theme-text-color opacity-75" download >Sample Lyrics File </Link>
            </div>

            {editUser?.lyrics && (
              <a href={editUser?.audio_lyrics} className="fw-bold my-2  d-block admin-input-description theme-text-color" download>
                <MessageBox classname="theme-text-color" text="Download Uploaded Lyrics File" />
              </a>
            )}

            <div className="d-flex flex-wrap justify-content-between gap-2">
              <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                <div className="imagedrop theme-border-color mt-1 p-1">
                  <input className="opacity-xlsx h-100 p-0 m-0 " type="file" accept=".xlsx" ref={AudioRef} onChange={handleLyrics} key={getLyrics ? getLyrics : "no"} />
                  <label type="button" className="position-absolute  theme-text-color">
                    <BsFiletypeXlsx className=" theme-text-color" />
                  </label>
                </div>
              </div>

              {(getSuccessLyrics || getDangerLyrics) && (
                <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                  <div className="imagedrop theme-border-color d-flex mt-1 p-1 justify-content-between">
                    <button type="button" className="w-75 h-100 bg-transparent"><FcFile /></button>
                    <button type="button" className="btn btn-close theme-text-color" onClick={handleClearFile} ></button>
                  </div>
                </div>
              )}
            </div>

            {getSuccessLyrics && (
              <p className="fw-bold text-success mt-2 theme-text-color admin-input-description"><FcOk className="me-1" />{getSuccessLyrics}</p>
            )}

            {getDangerLyrics && (
              <p className="fw-bold text-danger mt-2 theme-text-color admin-input-description"><FcHighPriority className="me-1" />{getDangerLyrics} </p>
            )}
          </Wrapper>

          <Wrapper>
            <MessageHeading text='Thumbnails' />
            <MessageLabel text="Audio Image Cover" />
            <MessageSubHeading text="Select the Audio image( 9:16 Ratio or 1080X1920px )" />
            <img src={editUser?.image_url} className="thumbnails-images" />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef1.current.click()} >
                  <input type="file" ref={fileInputRef1} onChange={(event) => handleFileChange(event, fileInputRef1, setSelectedFile1)} accept="image/*" style={{ display: "none" }} />
                  <label className="theme-text-color"><CameraRetroIcon className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0 ">
                          <img src={URL?.createObjectURL(selectedFile1)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => handleDelete(setSelectedFile1)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <MessageLabel text="Player Audio Thumbnail" />
            <MessageSubHeading text="Select the Audio image (16:9 Ratio or 1280X720px)" />
            <img src={editUser?.Player_thumbnail} alt="Image" className="thumbnails-images" />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef2.current.click()} >
                  <input type="file" ref={fileInputRef2} onChange={(event) => handleFileChange(event, fileInputRef2, setSelectedFile2)} accept="image/*" style={{ display: "none" }} />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0 ">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy  theme-border-color text-end">
                      <div className="row mx-0 ">
                        <div className="col-10 p-0 ">
                          <img src={URL?.createObjectURL(selectedFile2)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                          <button onClick={() => handleDelete(setSelectedFile2)} type="button" className="bg-transparent theme-text-color" ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <MessageLabel text="TV Thumbnail Image Cover" />
            <MessageSubHeading text="Select the audio image ( 16:9 Ratio or 1280X720px )" />
            <img src={editUser?.Player_thumbnail} alt="Image" className="thumbnails-images" />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef3.current.click()} >
                  <input type="file" ref={fileInputRef3} onChange={(event) => handleFileChange(event, fileInputRef3, setSelectedFile3)} accept="image/*" style={{ display: "none" }} />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0 ">
                <div>
                  {selectedFile3 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0">
                          <img src={URL?.createObjectURL(selectedFile3)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                          <button onClick={() => handleDelete(setSelectedFile3)} type="button" className="bg-transparent theme-text-color" ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </Wrapper>

          <Wrapper>
            <MessageHeading text='Access' />
            <MessageLabel text="User Access" />
            <MessageSubHeading text="Who Is Allowed To View This audio ?" />
            <select onChange={handleInput} name="access" id="access" value={editUser?.access} onClick={(event) => { setallliveuseraccess(event.target.value); }} className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" >
              {user_access?.map((item, key) => (<option value={item?.role} className="theme-bg-color theme-text-color">{item?.name}</option>))}
            </select>
            <div>{allaudiosuseraccess()}</div>
          </Wrapper>

          <Wrapper>
            <MessageHeading text="SEO" />
            <MessageLabel text="Website Page Title" />
            <input type="text" value={editUser?.website_page_title} name="website_page_title" onChange={handleInput} placeholder="website title" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" />
            <MessageLabel text="Website URL" />
            <input type="text" value={editUser?.website_URL} name="website_URL" onChange={handleInput} placeholder="website url" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" />
            <MessageLabel text="Meta Description" />
            <textarea type="text" value={editUser?.Meta_description} name="Meta_description" onChange={handleInput} placeholder="meta description" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"></textarea>
          </Wrapper>

          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this audio featured" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input name="featured" onChange={handleInputenable} defaultChecked={editUser?.featured == 1 ? true : false} checked={editUser?.featured == 1 ? true : false} type="checkbox" className="rs-input" />
                  <span name="featured" onChange={handleInputenable} value={editUser?.featured == 1 ? "1" : "0"} className="slider round" ></span>
                </label>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this audio active" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input name="active" onChange={handleInputenable} defaultChecked={editUser?.active == 1 ? true : false} checked={editUser?.active == 1 ? true : false} className="rs-input" type="checkbox" />
                  <span name="active" onChange={handleInputenable} value={editUser?.active == 1 ? "1" : "0"} className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable this audio as slider" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input name="banner" onChange={handleInputenable} defaultChecked={editUser?.banner == 1 ? true : false} checked={editUser?.banner == 1 ? true : false} type="checkbox" className="r-input" />
                  <span name="banner" onChange={handleInputenable} value={editUser?.banner == 1 ? "1" : "0"} className="slider round" ></span>
                </label>
              </div>
            </div>
          </Wrapper>
        </RightWrapper >
        <AdminCustomButton saveMethod={handleUpdate} saveText="Update audio" className="ms-auto" />
      </FormContainer >
    </>
  );
}

export default Edit_new_audio;