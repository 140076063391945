
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import './Iyzico_Failerpage.css';

function PPV_Failerpage() {
  const [counter, setCounter] = useState(5); // Initialize the countdown timer
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // const timer = setInterval(() => {
    //     setCounter((prevCounter) => prevCounter - 1);
    // }, 1000);

    // if (counter === 0) {
    //     clearInterval(timer);
    //     navigate("/home"); // Redirect to the home page after the countdown
    // }

    // return () => clearInterval(timer); // Clear the timer if the component unmounts
  }, [counter, navigate]);

  return (
    <div className="themeTwoBannerWrappers iyzicopaddingtoptop container mb-5">
      <h3 className="text-center theme-text-color priceTableBigHead ">
        <MdOutlineCancel className="MdOutlineCancelcolor" />
      </h3>
      <h2 className="pb-3 my-3 text-center theme-text-color SuccessfullyPayment">
        Your PPV Payment was Failed </h2>

      <p className="text-center theme-text-color my-3">
        Sorry for the inconvenience. Please review your profile details to confirm that everything is correct.
      </p>
      <p className="text-center theme-text-color pb-3 ">
        We appreciate you choosing our payment service. Your satisfaction is important to us, and we are here to assist you with any issues or questions you may have.
      </p>
      <div className="text-center">
        <Link to='/'><button className="btn">Click back to Home Page</button></Link>
      </div>
    </div>
  );
}

export default PPV_Failerpage;
