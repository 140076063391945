import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// // import JoditEditor from "jodit-react";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { toast } from "react-toastify";

import "./Contenteditaudio.css";
import loadings from "../../../assets/gif/loading.gif";
import { FcOk, FcHighPriority, FcFile } from "react-icons/fc";
import { BsFiletypeXlsx } from "react-icons/bs";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import { useTranslation } from "react-i18next";

function Contenteditaudio() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [getSuccessLyrics, setSuccessLyrics] = useState(null);
  const [getDangerLyrics, setDangerLyrics] = useState(null);
  const [getLyrics, setLyrics] = useState(null);
  const [getExampleUrl, setExampleUrl] = useState(null);
  const AudioRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    ppv_status: "",
    ppv_price: "",
    type: "",
    access: "",
    album_id: "",
    artists: "",
    rating: "",
    details: "",
    description: "",
    active: "",
    status: "",
    draft: "",
    featured: "",
    albumname: "",
    banner: "",
    duration: "",
    views: "",
    year: "",
    age_restrict: "",
    mp3_url: "",
    image: "",
    player_image: "",
    tv_image: "",
    search_tags: "",
    ios_ppv_price: "",
    uploaded_by: "",
    image_url: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [album, setAlbum] = useState([]);
  // console.log(age_restrictdata)
  const [age_restrict, setAge_restrict] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);


  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    // alert(event.target.value);
  };

  const handleChangeyear = (event) => {
    const value = event?.target?.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser({ ...editUser, year: value });
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const handleInputChangetag = (input) => {
    setNewTag(input);
  };

  const handleInputKeyPress = (event) => {
    if (event.key == "Enter" || event.key == " ") {
      event.preventDefault(); // Prevent the default behavior (adding space or newline)

      // Only add the tag if it's not empty
      if (newTag.trim() !== "") {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    }
  };

  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );

  const lyricsUpload = new FormData();
  lyricsUpload.append("lyrics", getLyrics);
  lyricsUpload.append("audio_id", id);
  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit.set("publish_time", timeValue);
    formDatapublishedit.set("publish_type", publishType);
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    albumname: editUser?.albumname,
    // ...editInputvalue,
    // other form data properties...
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      setExampleUrl(response?.data?.sample_audio_lyrics);
      const data = response?.data?.audios[0]?.selected_block_countries;
      const dataavailable_country =
        response?.data?.audios[0]?.available_countries;
      const dataartists = response?.data?.audios[0]?.selected_artists;
      const datacategories = response?.data?.audios[0]?.selected_categories;
      const datalanguages = response?.data?.audios[0]?.selected_Language;
      const dataAge_Restrict = response?.data?.audios[0]?.selected_Age_Restrict;
      // const datarelated_video = response?.data?.selected_related_video;

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);

      setLoading(false);
      var globelset = response?.data?.ppv_global_price;

      setInputValueAPI(globelset);
      // console.log(globelset)

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item.country,
        label: item.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.artist_id,
        label: item.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.category_id,
        label: item.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.language_id,
        label: item.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item.id,
      //   label: item.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      // setSelectedValuesrelated_video(formattedOptionsrelated_video);
      // setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);

      setTags(
        response?.data?.audios[0]?.search_tags
          ?.split(",")
          ?.map((tag) => tag.trim())
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);
      setAlbum(response?.data?.audio_albums);
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      setLivestream_source(response?.data?.Livestream_source);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.age,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item.id,
      //   label: item.id,
      // }));
      setLoading(false);

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      // block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      // available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      // artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      // categories: categories,
    });
    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      // related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      // languages: languages,
    });
    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      // Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formattedDatatype = convertToTitleCase(editUser?.type);

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audios[0];
      setEditUser(res);
      setEditname(res);

      setPublishType(res?.publish_type);
      setPublishTime(res?.publish_time);
      // console.log(resData)
      // console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length == 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (!selectedValueslanguages || selectedValueslanguages?.length == 0) {
      setValidationMessagelanguages("Please select at least one Language.");
      setIsValidationHiddenlanguages(false);
      focusInputRef = languageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const updatedTagsString = tags.join(", ");

    const editInputvalue = {
      title: editUser?.title,
      Content_Partner_id: 1,
      slug: editUser?.slug,
      ppv_status: editUser?.ppv_status,
      ppv_price: inputValue,
      type: editUser?.type,
      access: editUser?.access,
      album_id: editUser?.album_id,
      artists: editUser?.artists,
      rating: editUser?.rating,
      details: editUser?.details,
      description: editUser?.description,
      active: editUser?.active,
      status: editUser?.status,
      draft: editUser?.draft,
      featured: editUser?.featured,
      banner: editUser?.banner,
      ads_position: editUser?.ads_position,
      duration: editUser?.duration,
      views: editUser?.views,
      year: editUser?.year,
      albumname: editUser?.albumname,
      age_restrict: editUser?.age_restrict,
      mp3_url: editUser?.mp3_url,
      image: editUser?.image,
      player_image: editUser?.player_image,
      tv_image: editUser?.tv_image,
      search_tags: updatedTagsString,
      ios_ppv_price: editUser?.ios_ppv_price,
      uploaded_by: editUser?.uploaded_by,
      image_url: editUser?.image_url,
      Player_thumbnail: editUser?.Player_thumbnail,
      TV_Thumbnail: editUser?.TV_Thumbnail,
      pre_ads_category: editUser?.pre_ads_category,
      mid_ads_category: editUser?.mid_ads_category,
      post_ads_category: editUser?.post_ads_category,
      languages_id: selectedValueslanguages?.map((option) => option.value),
      artist_id: selectedValuesartists?.map((option) => option.value),
      country: selectedValues?.map((option) => option.value),
      category_id: selectedValuescategories?.map((option) => option.value),
      // related_videos: selectedValuesrelated_video.map((option) => option.value),
      // available_countries_id: selectedValuesavailable_country?.map(
      //   (option) => option.value
      // ),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let audioApiStatus = false;

    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    if (getDangerLyrics) {
      AudioRef.current.focus();
      return;
    }
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("audio_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Image-upload`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("audio_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/SEO`,
          formDataseo,
          { headers: headers }
        );

        const audioLyricsResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/lyrics-upload`,
          lyricsUpload,
          { headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
        if (audioLyricsResponse.data.status == true) {
          audioApiStatus = true;
        }
      } else if (resjson.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      audioApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(t(resultapi?.message));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      audioApiStatus
    ) {
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        //   console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function allaudiosuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  id="ppv_price"
                  name="ppv_price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  // onChange={(e) => setIos_ppv_price(e.target.value)}
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser?.ios_ppv_price}
                  className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.product_id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const handleLyrics = async (event) => {
    setSuccessLyrics(null);
    setDangerLyrics(null);
    const selectedFile = event.target.files;
    if (selectedFile) {
      const newFile = selectedFile[0];
      setLyrics(newFile);

      try {
        const lyricsDatas = new FormData();
        lyricsDatas.append("lyrics", newFile);
        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/audio/lyrics-validation`,
          lyricsDatas,
          { headers }
        );
        let result = response?.data;
        if (result.status == true) {
          setSuccessLyrics(result.message);
        } else {
          setSuccessLyrics(null);
          setDangerLyrics(result.message);
        }
      } catch (err) {
        var errorMessage = err.response.data.message;
        setSuccessLyrics(null);
        setDangerLyrics(errorMessage);
      }
    }
  };
  const handleClearFile = () => {
    setLyrics(null);
    setSuccessLyrics(null);
    setDangerLyrics(null);
  };


  return (
    <>
      {loading ? (
        <>
          <div className="overlay-container">
            <div className="overlay-icon">
              <img src={loadings} width={100} alt="Animated GIF" />
              Loading...
            </div>
          </div>
          <div className=" m-0 p-0">
            <section className="container-fluid  theme-bg-color-secondary  pb-1">
              <div className="col-lg-12 row py-3">
                <div className="col-6 col-sm-6 col-lg-6">
                  <h3 className=" theme-text-color">Edit Audio</h3>
                </div>
                <div className="col-6 col-sm-6 col-lg-6">
                  <div className="text-end">
                    <button onClick={handleUpdate} className="btn btn-primary">
                      Update Audio
                    </button>
                  </div>
                </div>
              </div>

              <div>
                {showOverlay && (<ResponseLoader />)}
              </div>

              <div className="row col-lg-12 ">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">{editname?.title}</h5>
                    <hr className=" theme-text-color"></hr>
                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Title<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Add the audio title in the textbox below
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="title"
                          name="title"
                          value={editUser?.title}
                          onChange={handleInput}
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          placeholder="Title"
                          ref={titleInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddentitle && (
                          <p>{validationMessagetitle}</p>
                        )}
                      </span>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">Slug</label>
                      <p className="t-1 theme-text-color">Add the Audio slug</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="slug"
                          id="slug"
                          onChange={handleInput}
                          value={editUser?.slug}
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          placeholder="slug"
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenslug && (
                          <p>{validationMessageslug}</p>
                        )}
                      </span>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">
                        Short Description
                      </label>
                      <p className="t-1 theme-text-color">
                        Add a short description of the audio below
                      </p>
                      <div className="mt-2">
                        <textarea
                          className="rs-input form-control-lg theme-bg-color theme-text-color short-description"
                          id="description"
                          name="description"
                          placeholder="Description"
                          onChange={handleInput}
                          value={editUser?.description}
                        />
                      </div>
                    </div>
                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0 theme-text-color">
                          Audio Details, Links, and Info
                        </label>
                        <div className="mt-2">
                          {/* <JoditEditor
                            className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                            value={editUser?.details}
                            onChange={(e) => {
                              handleChangeFirstdetails(e);
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0 theme-text-color">Duration</label>
                        <p className="t-1 theme-text-color">
                          Enter the audio duration in the following format
                          (Hours : Minutes : Seconds)
                        </p>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="duration"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            value={editUser?.duration}
                            maxLength="8"
                            placeholder="duration"
                            onChange={handleInputdata}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0 theme-text-color">Year</label>
                        <p className="t-1 theme-text-color">
                          Audio Released Year
                        </p>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="number"
                            value={editUser?.year}
                            onChange={handleChangeyear}
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            placeholder="Year"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">Organize</h5>
                      <hr className=" theme-text-color"></hr>

                      <div className=" text-start">
                        <label className="m-0 theme-text-color">
                          Audio Ratings
                        </label>
                        <p className="t-1 theme-text-color">
                          IMDb Ratings 10 out of 10
                        </p>
                        <div className="mt-2">
                          <select
                            className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                            id="rating"
                            name="rating"
                            size="lg"
                            onChange={handleInput}
                            value={editUser?.rating}
                          >
                            {/* <option value="0">0</option> */}
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0 theme-text-color">
                          Age Restrict
                        </label>
                        <p className="t-1 theme-text-color">
                          Select a Audio age Below
                        </p>
                        <div className="mt-2">
                          <select
                            className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                            id="age_restrict"
                            name="age_restrict"
                            onChange={handleInput}
                            value={editUser?.age_restrict}
                          >
                            <option value="">Choose an Age</option>
                            {age_restrictdata.map((item) => (
                              <option value={item.id}>{item.slug}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0 theme-text-color">Album</label>
                        <p className="t-1 theme-text-color">
                          Select A Audio Album Below
                        </p>
                        <div className="mt-2">
                          <select
                            className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                            id="album_id"
                            name="album_id"
                            onChange={handleInput}
                            value={editUser?.album_id}
                          >
                            <option value="">Choose a Album</option>
                            {album?.map((item, key) => (
                              <option value={item?.id}>
                                {item?.albumname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">
                          Cast and Crew
                        </label>
                        <p className="t-1 theme-text-color">
                          Add artists for the audio below
                        </p>

                        <div className="mt-2">
                          <Select
                            options={optionsartists}
                            isMulti
                            className="  theme-bg-color-secondary  theme-text-color"
                            onChange={handleSelectChangeartists}
                            value={selectedValuesartists}
                          />
                        </div>
                      </div>
                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">
                          Category<span className="mandatory">*</span>
                        </label>
                        <p className="t-1 theme-text-color">
                          Select a Audio Category Below
                        </p>

                        <div className="mt-2">
                          <Select
                            options={optionscategories}
                            isMulti
                            className="  theme-bg-color-secondary  theme-text-color"
                            onChange={handleSelectChangecategories}
                            value={selectedValuescategories}
                            ref={categoryInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddencategory && (
                            <p>{validationMessagecategory}</p>
                          )}
                        </span>
                      </div>

                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">
                          Language<span className="mandatory">*</span>
                        </label>
                        <p className="t-1 theme-text-color">
                          Select a Audio Language Below
                        </p>
                        <div className="text-dark">
                          <Select
                            options={optionslanguages}
                            isMulti
                            className="  theme-bg-color-secondary  theme-text-color"
                            onChange={handleSelectChangelanguages}
                            value={selectedValueslanguages}
                            ref={languageInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddenlanguages && (
                            <p>{validationMessagelanguages}</p>
                          )}
                        </span>
                      </div>

                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">Country</label>
                        <p className="t-1 theme-text-color">
                          Select a Audio country Below
                        </p>
                        <div className="text-dark">
                          <Select
                            options={options}
                            isMulti
                            className="  theme-bg-color-secondary  theme-text-color"
                            onChange={handleSelectChange}
                            value={selectedValues}
                          />
                        </div>
                      </div>

                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">
                          Available Country
                        </label>
                        <p className="t-1 theme-text-color">
                          Select a Audio country Below
                        </p>
                        <div className="text-dark">
                          <Select
                            options={optionsavailable_country}
                            isMulti
                            className="  theme-bg-color-secondary  theme-text-color"
                            onChange={handleSelectChangeavailable_country}
                            value={selectedValuesavailable_country}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Search Tags</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <p className="p2 theme-text-color">
                        You don't have any search keywords.
                      </p>

                      <div className="mt-2">
                        <TagsInput
                          value={tags}
                          onChange={handleTagsChange}
                          inputValue={newTag}
                          classNames="rs-input form-control-lg theme-bg-color theme-text-color"
                          onChangeInput={handleInputChangetag}
                          inputProps={{ onKeyPress: handleInputKeyPress }} // Adding key press event handler
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-start mb-3">
                    <button onClick={handleUpdate} className="btn btn-primary">
                      Update Audio
                    </button>
                  </div>
                </div>

                {/* Second Part of Section  */}

                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  {editUser?.mp3_url ? (
                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">
                        Audio {formattedDatatype}
                      </h5>
                      <hr className=" theme-text-color"></hr>

                      <div className="text-start">
                        {/* <ReactPlayer
                          url={editUser?.mp3_url}
                          controls={true} 
                          width="100%"
                          height="50px"
                        /> */}
                      </div>
                    </div>
                  ) : null}

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Audio Lyrics</h5>
                    <hr className=" theme-text-color"></hr>

                    <label className="m-0 theme-text-color">
                      Upload Audio Lyrics
                    </label>
                    <p className="t-1 theme-text-color">
                      (Ex:xlsx{" "}
                      <a
                        className="fw-bold text-info theme-text-color"
                        href={getExampleUrl}
                        download
                      >
                        Sample Lyrics File
                      </a>
                      )
                      <label className="d-block my-2 ">
                        {editUser?.lyrics && (
                          <a
                            href={editUser?.audio_lyrics}
                            className="fw-bold text-info theme-text-color"
                          >
                            Download Uploaded Lyrics File
                          </a>
                        )}
                      </label>
                    </p>
                    <div className="d-flex flex-wrap justify-content-between gap-2">
                      <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                        <div className="imagedrop  mt-1 p-1">
                          <input
                            className="opacity-xlsx h-100 p-0 m-0 "
                            type="file"
                            accept=".xlsx"
                            ref={AudioRef}
                            onChange={handleLyrics}
                            key={getLyrics ? getLyrics : "no"}
                          />
                          <label
                            type="button"
                            className="position-absolute  theme-text-color"
                          >
                            <BsFiletypeXlsx className=" theme-text-color" />
                          </label>
                        </div>
                      </div>
                      {(getSuccessLyrics || getDangerLyrics) && (
                        <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                          <div className="imagedrop d-flex mt-1 p-1 justify-content-between">
                            <button type="button" className="w-75 h-100">
                              <FcFile />
                            </button>
                            <button
                              type="button"
                              className="btn btn-close theme-text-color"
                              onClick={handleClearFile}
                            ></button>
                          </div>
                        </div>
                      )}
                    </div>
                    {getSuccessLyrics && (
                      <p className="fw-bold text-success mt-2 theme-text-color">
                        {" "}
                        <FcOk className="me-1" />
                        {getSuccessLyrics}{" "}
                      </p>
                    )}
                    {getDangerLyrics && (
                      <p className="fw-bold text-danger mt-2 theme-text-color">
                        <FcHighPriority className="me-1" />
                        {getDangerLyrics}
                      </p>
                    )}
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Thumbnails</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Audio Image Cover
                      </label>
                      <p className="t-1 theme-text-color">
                        Select the Audio image( 9:16 Ratio or 1080X1920px )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.image_url}
                            // alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>

                      {/* <img src={editUser?.image_url} width="150" height="100" /> */}
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div
                              className="imagedrop"
                              onClick={() => fileInputRef1.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={(event) =>
                                  handleFileChange(
                                    event,
                                    fileInputRef1,
                                    setSelectedFile1
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div>
                              {selectedFile1 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile1
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile1)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Player Audio Thumbnail{" "}
                      </label>
                      <p className="t-1 theme-text-color">
                        Select the Audio image (16:9 Ratio or 1280X720px)
                      </p>
                      {/* <img
              src={editUser?.Player_thumbnail}
              width="150"
              height="100"
            /> */}

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.Player_thumbnail}
                            // alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>

                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div
                              className="imagedrop"
                              onClick={() => fileInputRef2.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef2}
                                onChange={(event) =>
                                  handleFileChange(
                                    event,
                                    fileInputRef2,
                                    setSelectedFile2
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div>
                              {selectedFile2 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile2
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile2)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        TV Thumbnail Image Cover
                      </label>
                      <p className="t-1 theme-text-color">
                        Select the audio image ( 16:9 Ratio or 1280X720px )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.Player_thumbnail}
                            // alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>

                      {/* <img src={editUser?.TV_Thumbnail} width="150" height="100" /> */}
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div
                              className="imagedrop"
                              onClick={() => fileInputRef3.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef3}
                                onChange={(event) =>
                                  handleFileChange(
                                    event,
                                    fileInputRef3,
                                    setSelectedFile3
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div>
                              {selectedFile3 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile3
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile3)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">Access</h5>
                      <hr className=" theme-text-color"></hr>

                      <div className="col-sm-12">
                        <label className="m-0 theme-text-color">
                          User Access
                        </label>
                        <p className="p1 theme-text-color">
                          Who Is Allowed To View This audio ?
                        </p>
                        <select
                          onChange={handleInput}
                          name="access"
                          id="access"
                          value={editUser?.access}
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          onClick={(event) => {
                            setallliveuseraccess(event.target.value);
                          }}
                        >
                          {user_access?.map((item, key) => (
                            <option value={item?.role}>{item?.name}</option>
                          ))}
                        </select>
                      </div>

                      <div>{allaudiosuseraccess()}</div>
                    </div>

                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">SEO</h5>
                      <hr className=" theme-text-color"></hr>

                      <div className="col-sm-12">
                        <label className="m-0 theme-text-color">
                          Website Page Title
                        </label>

                        <div className=" mt-2">
                          <input
                            type="text"
                            value={editUser?.website_page_title}
                            name="website_page_title"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            onChange={handleInput}
                          // placeholder="website title"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 mt-2">
                        <label className="m-0">Website URL</label>

                        <div className=" mt-2">
                          <input
                            type="text"
                            value={editUser?.website_URL}
                            name="website_URL"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            onChange={handleInput}
                          // placeholder="website url"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2">
                        <label className="m-0">Meta Description</label>

                        <div className=" mt-2">
                          <textarea
                            type="text"
                            value={editUser?.Meta_description}
                            name="Meta_description"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            onChange={handleInput}
                          // placeholder="meta description"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">Status Settings</h5>
                      <hr className=" theme-text-color"></hr>

                      <div className="col-sm-12 row">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2 theme-text-color">
                            Is this Audio Featured
                          </p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="featured"
                              onChange={handleInputenable}
                              className="rs-input"
                              defaultChecked={
                                editUser?.featured == 1 ? true : false
                              }
                              checked={editUser?.featured == 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="featured"
                              onChange={handleInputenable}
                              value={editUser?.featured == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-12 row mt-2">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2 theme-text-color">
                            Is this Audio Active
                          </p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="active"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.active == 1 ? true : false
                              }
                              checked={editUser?.active == 1 ? true : false}
                              className="rs-input"
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="active"
                              onChange={handleInputenable}
                              value={editUser?.active == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 row mt-2">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2 theme-text-color">
                            Is this Audio display in Banner
                          </p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="banner"
                              onChange={handleInputenable}
                              className="r-input"
                              defaultChecked={
                                editUser?.banner == 1 ? true : false
                              }
                              checked={editUser?.banner == 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="banner"
                              onChange={handleInputenable}
                              value={editUser?.banner == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <div className=" m-0 p-0">
          <section className="container-fluid  theme-bg-color-secondary  pb-1">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3 className=" theme-text-color">Edit Audio</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Audio
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && (<ResponseLoader />)}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">{editname?.title}</h5>
                  <hr className=" theme-text-color"></hr>
                  <div className=" text-start">
                    <label className="m-0 theme-text-color">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1 theme-text-color">
                      Add the audio title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={editUser?.title}
                        onChange={handleInput}
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">Slug</label>
                    <p className="t-1 theme-text-color">Add the Audio slug</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="slug"
                        id="slug"
                        onChange={handleInput}
                        value={editUser?.slug}
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        placeholder="slug"
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">
                      Short Description
                    </label>
                    <p className="t-1 theme-text-color">
                      Add a short description of the audio below
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg theme-bg-color theme-text-color short-description"
                        id="description"
                        name="description"
                        placeholder="Description"
                        onChange={handleInput}
                        value={editUser?.description}
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">
                        Audio Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        {/* <JoditEditor
                          className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirstdetails(e);
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Duration</label>
                      <p className="t-1 theme-text-color">
                        Enter the audio duration in the following format (Hours
                        : Minutes : Seconds)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          value={editUser?.duration}
                          maxLength="8"
                          placeholder="duration"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Year</label>
                      <p className="t-1 theme-text-color">
                        Audio Released Year
                      </p>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="number"
                          value={editUser?.year}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Organize</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Audio Ratings
                      </label>
                      <p className="t-1 theme-text-color">
                        IMDb Ratings 10 out of 10
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.rating}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">
                        Age Restrict
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio age Below
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="age_restrict"
                          name="age_restrict"
                          onChange={handleInput}
                          value={editUser?.age_restrict}
                        >
                          <option value="">Choose an Age</option>
                          {age_restrictdata.map((item) => (
                            <option value={item.id}>{item.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">Album</label>
                      <p className="t-1 theme-text-color">
                        Select A Audio Album Below
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="album_id"
                          name="album_id"
                          onChange={handleInput}
                          value={editUser?.album_id}
                        >
                          <option value="">Choose a Album</option>
                          {album?.map((item, key) => (
                            <option value={item?.id}>{item?.albumname}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Cast and Crew
                      </label>
                      <p className="t-1 theme-text-color">
                        Add artists for the audio below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio Category Below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={optionscategories}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Language<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio Language Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguages && (
                          <p>{validationMessagelanguages}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">Country</label>
                      <p className="t-1 theme-text-color">
                        Select a Audio country Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={options}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Available Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio country Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className="  theme-bg-color-secondary  theme-text-color"
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Search Tags</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className="col-sm-12">
                    <p className="p2 theme-text-color">
                      You don't have any search keywords.
                    </p>

                    <div className="mt-2">
                      <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        classNames="rs-input form-control-lg theme-bg-color theme-text-color"
                        onChangeInput={handleInputChangetag}
                        inputProps={{ onKeyPress: handleInputKeyPress }} // Adding key press event handler
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Audio
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                {editUser?.mp3_url ? (
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">
                      Audio {formattedDatatype}
                    </h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="text-start">
                      {/* <ReactPlayer
                        url={editUser?.mp3_url}
                        controls={true} 
                        width="100%"
                        height="50px"
                      /> */}
                    </div>
                  </div>
                ) : null}

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Audio Lyrics</h5>
                  <hr className=" theme-text-color"></hr>

                  <label className="m-0 theme-text-color">
                    Upload Audio Lyrics
                  </label>
                  <p className="t-1 theme-text-color">
                    (Ex:xlsx{" "}
                    <a
                      className="fw-bold text-info theme-text-color"
                      href={getExampleUrl}
                      download
                    >
                      Sample Lyrics File
                    </a>
                    )
                    <label className="d-block my-2 ">
                      {editUser?.lyrics && (
                        <a
                          href={editUser?.audio_lyrics}
                          className="fw-bold text-info theme-text-color"
                        >
                          Download Uploaded Lyrics File
                        </a>
                      )}
                    </label>
                  </p>
                  <div className="d-flex flex-wrap justify-content-between gap-2">
                    <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                      <div className="imagedrop  mt-1 p-1">
                        <input
                          className="opacity-xlsx h-100 p-0 m-0 "
                          type="file"
                          accept=".xlsx"
                          ref={AudioRef}
                          onChange={handleLyrics}
                          key={getLyrics ? getLyrics : "no"}
                        />
                        <label
                          type="button"
                          className="position-absolute  theme-text-color"
                        >
                          <BsFiletypeXlsx className=" theme-text-color" />
                        </label>
                      </div>
                    </div>
                    {(getSuccessLyrics || getDangerLyrics) && (
                      <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                        <div className="imagedrop d-flex mt-1 p-1 justify-content-between">
                          <button type="button" className="w-75 h-100">
                            <FcFile />
                          </button>
                          <button
                            type="button"
                            className="btn btn-close theme-text-color"
                            onClick={handleClearFile}
                          ></button>
                        </div>
                      </div>
                    )}
                  </div>
                  {getSuccessLyrics && (
                    <p className="fw-bold text-success mt-2 theme-text-color">
                      {" "}
                      <FcOk className="me-1" />
                      {getSuccessLyrics}{" "}
                    </p>
                  )}
                  {getDangerLyrics && (
                    <p className="fw-bold text-danger mt-2 theme-text-color">
                      <FcHighPriority className="me-1" />
                      {getDangerLyrics}
                    </p>
                  )}
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Audio Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Audio image( 9:16 Ratio or 1080X1920px )
                    </p>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.image_url}
                          // alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    {/* <img src={editUser?.image_url} width="150" height="100" /> */}
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Player Audio Thumbnail{" "}
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Audio image (16:9 Ratio or 1280X720px)
                    </p>
                    {/* <img
              src={editUser?.Player_thumbnail}
              width="150"
              height="100"
            /> */}

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.Player_thumbnail}
                          // alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      TV Thumbnail Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the audio image ( 16:9 Ratio or 1280X720px )
                    </p>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.Player_thumbnail}
                          // alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    {/* <img src={editUser?.TV_Thumbnail} width="150" height="100" /> */}
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Access</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        User Access
                      </label>
                      <p className="p1 theme-text-color">
                        Who Is Allowed To View This audio ?
                      </p>
                      <select
                        onChange={handleInput}
                        name="access"
                        id="access"
                        value={editUser?.access}
                        className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{allaudiosuseraccess()}</div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">SEO</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        Website Page Title
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_page_title}
                          name="website_page_title"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                        // placeholder="website title"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Website URL</label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_URL}
                          name="website_URL"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                        // placeholder="website url"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Meta Description</label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          value={editUser?.Meta_description}
                          name="Meta_description"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                        // placeholder="meta description"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Status Settings</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this Audio Featured
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              editUser?.featured == 1 ? true : false
                            }
                            checked={editUser?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInputenable}
                            value={editUser?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this Audio Active
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.active == 1 ? true : false
                            }
                            checked={editUser?.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInputenable}
                            value={editUser?.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this Audio display in Banner
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInputenable}
                            className="r-input"
                            defaultChecked={
                              editUser?.banner == 1 ? true : false
                            }
                            checked={editUser?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInputenable}
                            value={editUser?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Contenteditaudio;
